export const generateFilterLabels = (
  microsFilterList,
  statusFilterList,
  search,
  sponsorsFilterList
) => {
  let isActiveMicrosFilter = false;
  let isActiveStatusFilter = false;
  let isActiveSponsorFilter = false;
  const microsLabels = [];
  const statusLabels = [];
  const sponsorLabels = [];

  if (microsFilterList) {
    isActiveMicrosFilter = microsFilterList.some((el) => {
      return el.active;
    });
    microsFilterList.forEach((micro) => {
      if (micro.active) {
        microsLabels.push({ name: micro.label, id: micro.id });
      }
    });
  }

  if (sponsorsFilterList) {
    isActiveSponsorFilter = sponsorsFilterList.some((el) => {
      return el.active;
    });
    sponsorsFilterList.forEach((micro) => {
      if (micro.active) {
        sponsorLabels.push({ name: micro.label, id: micro.id });
      }
    });
  }

  if (statusFilterList) {
    isActiveStatusFilter = statusFilterList.some((el) => {
      if (el.nested) {
        return el.nested.some((al) => {
          return al.active;
        });
      } else {
        return el.active;
      }
    });
    if (isActiveStatusFilter) {
      statusFilterList.forEach((status) => {
        if (status.active) {
          statusLabels.push({ name: status.label, id: status.id });
          if (status.nested) {
            status.nested.forEach((nes) => {
              statusLabels.push({ name: nes.label, id: nes.id });
            });
          }
        } else {
          if (status.nested) {
            status.nested.forEach((nes) => {
              if (nes.active) {
                statusLabels.push({ name: nes.label, id: nes.id });
              }
            });
          }
        }
      });
    }
  }

  if (search) {
  }

  const isActive = isActiveMicrosFilter || isActiveStatusFilter || isActiveSponsorFilter;

  return { isActive, microsLabels, statusLabels, sponsorLabels };
};
