export const BLENDFC_TABLE_HEADS = [
  {
    readName: 'Time',
    sortName: 'time',
  },
  {
    readName: 'Transaction',
    sortName: 'code',
  },
  {
    readName: 'Name',
    sortName: 'name',
  },
  {
    readName: 'Age',
    sortName: 'age',
  },
  {
    readName: 'Product',
    sortName: 'product',
  },
  {
    readName: 'Price',
    sortName: 'price',
  },
]
