import React from "react";
import { useParams } from 'react-router-dom';
import { useApplication } from "../../../hooks/useApplication";
import { useProfile } from "../../../api/global/hooks";
import { Chat } from "../../../components/Chat/Chat";
import Loader from '../../../components/Loader/Loader';
import { KidData } from "../../../components/Chat/KidData/KidData";


export default function ChatInboxPage() {
  const { id } = useParams();
  const { data: profile } = useProfile();
  const { application, isLoading } = useApplication(id);

  if (!application || isLoading || !profile || !profile.id) return <Loader />

  return (
    <div className="flex-1 justify-between flex flex-col h-screen">
      <KidData user={application.user} />
      <Chat application={application} />
    </div>
  );
}
