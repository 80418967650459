import styled, { css } from 'styled-components';


export const KIDS_TABLE_HEADS = [
  {
    readName: 'Name',
    sortName: 'name',
  },
  {
    readName: 'Age',
    sortName: 'age',
  },
  {
    readName: 'Location',
    sortName: 'location',
  },
  {
    readName: 'Micros',
    sortName: 'micros',
  }
]

export const TextArea = styled.textarea`
  border-radius: 10px;
  background-color: rgba(168, 172, 175, 0.15);
  outline: none;
  padding: 15px;
  color: 465C8B;
  font-weight: 700;
  &::placeholder {
    font-size: 1rem;
    line-height: 1;
    color: #a8acaf;
  }
`;

export const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  height: 100%;
  min-width: 150px;
  display: inline-block;
  ${(props) =>
    props.primary === "green" &&
    css`
      background-color: #93c178;
    `}
`;