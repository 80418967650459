import styled from "styled-components";

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #465c8b;
  border-radius: 21px;
  background-color: transparent;
  border: 2px solid #465c8b;
  padding: 13px 12px;
  height: 100%;
  min-width: 150px;
  display: inline-block;
  padding: 0 1.375rem;
`;

export const TransparentButton = Button