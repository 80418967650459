import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ReactComponent as LogoMonochrome } from "../../../assets/icon/logo-monochrome.svg";
import { ReactComponent as LogoColor } from "../../../assets/icon/logo-color.svg";
import { ReactComponent as LogoFooter } from "../../../assets/icon/logo-footer.svg";
import { useProfile } from "../../../api/global/hooks";
import Loader from "../../Loader/Loader";

export default function AppHeaderBar({ isFooter = false, isAboutPage }) {
  const history = useHistory();

  const { data: profile, isLoading } = useProfile({ isPreAuth: true });

  if (isLoading) return <Loader />;

  return (
    <header>
      <div className={`container max-w-4xl flex mt-16 ${isFooter ? 'mb-16 justify-end' : 'justify-between'}`}>
        {!isFooter && (
          <NavLink to="/">
            {history.location.pathname === "/" ? (
              <LogoMonochrome className={isAboutPage ? '-mt-5' : ''} />
            ) : (
              <LogoColor />
            )}
          </NavLink>
        )}
        <nav className="flex items-center">
          <ul className="flex">
            <li>
              <NavLink
                to="/popular"
                activeClassName="selected"
                className="font-bold hover:text-custom-green"
              >
                Popular
              </NavLink>
            </li>
            <li className="ml-10">
              <NavLink
                to="/"
                exact
                activeClassName="selected"
                className="font-bold hover:text-custom-green"
              >
                About
              </NavLink>
            </li>
            <li className="ml-10">
              <NavLink
                to="/faq"
                activeClassName="selected"
                className="font-bold hover:text-custom-green"
              >
                FAQs
              </NavLink>
            </li>
            <li className="ml-10">
              {profile ? (
                <NavLink
                  to="/dashboard"
                  activeClassName="selected"
                  className="font-bold hover:text-custom-green"
                >
                  Dashboard
                </NavLink>
              ) : (
                <NavLink
                  to="/dashboard"
                  activeClassName="selected"
                  className="font-bold hover:text-custom-green"
                >
                  Login
                </NavLink>
              )}
            </li>
            <li className="ml-10">
              <NavLink
                to="/apply"
                activeClassName="selected"
                className="font-bold hover:text-custom-green"
              >
                Apply
              </NavLink>
            </li>
          </ul>
        </nav>
        {isFooter && <LogoFooter className="ml-10" />}
      </div>
    </header>
  );
}
