import { Route, Switch } from "react-router";
import DashboardPage from '../../../pages/Dashboard/DashboardPage'
import ProfilePage from '../../../pages/Dashboard/Profile/ProfilePage';
import ProfileEditPage from '../../../pages/Dashboard/Profile/ProfileEditPage';
import MicrosPage from "../../../pages/Dashboard/Micros/MicrosPage";
import MicrosNewPage from "../../../pages/Dashboard/Micros/MicrosNewPage";
import MicrosItemPage from "../../../pages/Dashboard/Micros/MicrosItemPage";
import KidsChatPage from '../../../pages/Dashboard/Kidspage/KidsChatPage';
import KidsPage from '../../../pages/Dashboard/Kidspage/KidsPage';
import ChatInboxPage from "../../../pages/Dashboard/InboxPage/ChatInboxPage";
import InboxPage from '../../../pages/Dashboard/InboxPage/InboxPage';
import MicroEdit from "../../../pages/Dashboard/Micros/MicrosEdit";
import { SponsorApplication } from "../../../pages/Dashboard/Sponsors/SponsorApplication";
import { SponsorsPage } from "../../../pages/Dashboard/Sponsors/SponsorsPage";
import { SponsorPage } from "../../../pages/Dashboard/Sponsors/SponsorPage";
import BlendFCPage from "../../../pages/Dashboard/BlendFCPage/BlendFCPage";


export default function DashboardContent() {
  return (
    <>
      <Switch>
        <Route exact path="/dashboard">
          <DashboardPage />
        </Route>
        <Route exact path="/dashboard/profile">
          <ProfilePage />
        </Route>
        <Route path="/dashboard/profile/edit">
          <ProfileEditPage />
        </Route>
        <Route exact path="/dashboard/micros">
          <MicrosPage />
        </Route>
        <Route path="/dashboard/micros/new">
          <MicrosNewPage />
        </Route>
        <Route path="/dashboard/micros/:id/edit">
          <MicroEdit />
        </Route>
        <Route exact path="/dashboard/micros/:id">
          <MicrosItemPage />
        </Route>
        <Route path="/dashboard/kids/chat/:id">
          <KidsChatPage />
        </Route>
        <Route exact path="/dashboard/kids">
          <KidsPage />
        </Route>
        <Route path="/dashboard/inbox/chat/:id">
          <ChatInboxPage />
        </Route>
        <Route path="/dashboard/inbox">
          <InboxPage />
        </Route>
        <Route path="/dashboard/integrations/blendfc">
          <BlendFCPage />
        </Route>

        <Route path="/dashboard/sponsorApps/:id">
          <SponsorApplication />
        </Route>
        <Route path="/dashboard/sponsors/:id">
          <SponsorPage />
        </Route>
        <Route path="/dashboard/sponsors">
          <SponsorsPage />
        </Route>

      </Switch>
    </>
  );
}
