import DashboardContent from "../DashboardContent/DashboardContent";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";

export default function DashboardLayout() {
  return (
    <div className="flex flex-no-wrap h-screen overflow-y-hidden">
      <DashboardSidebar />
      <main className="h-screen overflow-y-auto bg-white text-custom-blue">
        <DashboardContent />
      </main>
    </div>
  );
}
