import { useMemo } from 'react';
import { useAllApplicationsQuery } from '../api/micros/hooks.queries';

export function useApplication(id) {
  const { data: applications, isLoading, isFetching } = useAllApplicationsQuery();

  const application = useMemo(() => {
    if (isLoading || !id || !applications) return null;

    return applications.find(item => item.id === parseInt(id));
  }, [applications, id, isLoading])

  return {
    isLoading: isLoading || isFetching,
    application,
  }
}