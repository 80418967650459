import styled from "styled-components";

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 11px 12px;
  display: inline-block;
  min-width: 136px;
  border: 2px solid #465c8b;
`;

export const BlueButton = Button