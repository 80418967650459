import styled, { css } from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/icon/close-icon.svg";
import { ReactComponent as NoImageIcon } from "../../../assets/icon/no-image-icon.svg";
import { capitalize } from '../../../utils/index';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import Loader from '../../../components/Loader/Loader';
import DashboardLinearChart from "../../../components/Dashboard/DashboardLinearChart/DashboardLinearChart";
import DashboardPieChart from "../../../components/Dashboard/DashboardPieChart/DashboardPieChart";
import MicroInbox from '../InboxPage/MicroInbox';
import { useMicroApplicationsQuery, useMicroOverviewQuery } from "../../../api/micros/hooks.queries";
import { classesMeta } from "../../../styles";
import { useDeleteMicro } from "../../../api/micros/hooks.mutations";
import { TransparentButton } from "../../../components/Buttons/TransparentButton";
import { BlueButton } from "../../../components/Buttons/BlueButton";
import { ActionMicro } from "./Action";


const ProgressBar = styled.button`
  .progress {
    background: #ebf4f7;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 0px;
    display: flex;
    height: 7px;
    width: 100%;
  }

  .progress-value {
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #f7ef6a;
    height: 7px;
    width: 0;
  }

  ${(props) => {
    const getPersentOfAmount = ((props.value * 100) / props.maxValue).toFixed(
      0
    );
    return (
      props.maxValue &&
      props.value &&
      css`
        @keyframes load {
          0% {
            width: 0;
          }
          100% {
            width: ${getPersentOfAmount}%;
          }
        }
      `
    );
  }}
`;

export default function MicrosItemPage() {

  const history = useHistory();

  const { id } = useParams();
  const { data: microOverview, isLoading: microOverviewIsLoading } = useMicroOverviewQuery(id);
  const { data: applications, isLoading: microApplicationsIsLoading } = useMicroApplicationsQuery(id);
  const { mutate, isLoading } = useDeleteMicro();

  const deleteMicro = () => {
    if (window.confirm(`Delete micro?\nAll applications will be withdrawn`)) {
      mutate(id);
    }
  }

  if (microOverviewIsLoading || microApplicationsIsLoading || isLoading) return <Loader />
  const { micro, statistics } = microOverview;


  return (
    <>
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex justify-between">
        <h1 className="text-4xl font-bold">Micro Overview</h1>
        <div className="flex items-center justify-center">
          <TransparentButton onClick={() => history.push(`/dashboard/micros/${id}/edit`)} className="text-sm mr-3">Edit</TransparentButton>
          <BlueButton onClick={deleteMicro} className="text-sm mr-3">Delete</BlueButton>
          <ActionMicro micro={micro} />
          <button className="rounded-full bg-custom-blue">
            <CloseIcon
              className="p-3 fill-current text-custom-yellow"
              width="38"
              height="38"
              onClick={() => history.push('/dashboard/micros')}
            />
          </button>
        </div>
      </div>
      <div>
        <div className="container max-w-4xl p-4 pt-8">
          <div className="flex">
            <div className="flex-grow pr-4">
              <div className="items-center mb-9">
                <span className="font-museo-sans text-sm text-custom-gray w-20 text-right mr-5">
                  Name Micro:
                </span>
                <span
                  className="font-semibold"
                  style={{ letterSpacing: "0.15px", fontSize: "1.1rem" }}
                >
                  {micro.name}
                </span>
              </div>
              <div className="items-center flex mb-9">
                <span className="font-museo-sans text-sm text-custom-gray w-20 text-right mr-5">
                  Status:
                </span>
                <div className={classesMeta[capitalize(micro.status)]}> {capitalize(micro.status)}</div>
              </div>

              <div className="items-start flex">
                <span className="font-museo-sans text-sm text-custom-gray w-20 text-right mr-5">
                  Description:
                </span>
                <p className="text-sm font-thin" style={{ letterSpacing: "0.12px" }}>{micro.description}</p>
              </div>
            </div>
            <div className="w-1/6 pl-4 flex flex-col items-end">
              <div
                className="w-24 h-24 overflow-hidden rounded-xl flex items-center justify-center"
                style={{ background: micro.image ? null : "#eeeeee" }}
              >
                {micro.image
                  ? <img src={micro.image} alt="micro-img" />
                  : <NoImageIcon />
                }
              </div>
            </div>
          </div>
        </div>
        <div
          className="border-b border-t bo px-8 py-5 mb-6 flex justify-between"
          style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
        >
          <h2 className="text-base font-semibold">Timeline & Statistics</h2>
        </div>
        <div className="container max-w-4xl p-4 pt-8">
          <ProgressBar
            as="div"
            className="bg-custom-green px-5 py-5 rounded-xl mb-5"
            value={micro.current_count}
            maxValue={micro.max_count}
          >
            <div className="flex justify-between mb-3">
              <span className="text-custom-white">
                Total applications amount:
              </span>
              <span style={{ color: "#6172a4" }}>{`${applications.length} of ${micro.max_count}`}</span>
            </div>
            <div className="progress">
              <div className="progress-value"></div>
            </div>
          </ProgressBar>
          <div className="flex flex-row">
            <div className="w-1/2 pr-4">
              <DashboardLinearChart chart={statistics[0]} />
            </div>
            <div className="w-1/2 pl-4">
              <DashboardPieChart chart={statistics[1]} />
            </div>
          </div>
        </div>
        <div
          className="border-b border-t bo px-8 py-5 flex justify-between"
          style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
        >
          <h2 className="text-base font-semibold">
            Inbox <span className="text-custom-green">({applications.length})</span>
          </h2>
        </div>
      </div>
      <div className="min-h-screen">
        <MicroInbox
          applications={applications}
        />
      </div>
    </>
  );
}
