import { useEffect, useRef, useState } from "react";
import { ReactComponent as NoImageIcon } from "../../assets/icon/no-image-icon.svg";

export const InputFile = ({
  src,
  setFile,
}) => {

  const inputFile = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (src) setImageUrl(src);
  }, [src])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setImageUrl(URL.createObjectURL(event.target.files[0]));
    }
  }
  const openFilePicker = () => inputFile && inputFile.current.click();

  return (
    <div>

      <input
        type="file"
        accept="image/*"
        ref={inputFile}
        onChange={onImageChange}
        className="invisible"
      />
      <div className="w-1/4 pl-4 cursor-pointer" onClick={openFilePicker} >

        <div
          className="w-40 h-40 rounded-xl overflow-hidden flex items-center justify-center bg-cover"
        >
          {
            imageUrl ?
              <img src={imageUrl} alt='user-avatar' />
              : <NoImageIcon className="-mt-4" />
          }

        </div>

        <button

          type="button"
          className="text-xs text-custom-gray font-museo-sans whitespace-nowrap	"
        > Upload photo</button>

      </div>
    </div>
  )
}