import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useApply } from "../../api/global/hooks";
import { Button } from '../../pages/Dashboard/MainPage/LoginPage';
import InputField from "../InputField/InputField";
import Loader from "../Loader/Loader";
import TextAreaField from "../TextAreaField/TextAreaField";
import { countries_codes } from '../../utils/country_codes'
import SelectField from "../SelectField/SelectField";

const Radio = styled.input`
  display: none;

  & + .checkmark {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(168, 172, 175, 0.5);
    margin-right: 0.75rem;
    position: relative;
  }

  &:checked ~ .checkmark::after {
    display: block;
  }

  & + .checkmark::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #465c8b;
  }
`;

const MONEY_RANGES = [
  "< $100 - $1,000",
  "$25,001 - $100,000",
  "$1,001 - $5,000",
  "$100,001 - $1 million",
  "$5,001 - $25,000",
  "Above $1 million"
]

let telephone_codes = countries_codes.map(c => ({value:c.dial_code, label:`${c.code} (${c.dial_code})`}))

export const ApplySponsor = ({ defaultValues = null, readOnly = false } = {}) => {
  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });


  const [typePerson, setTypePerson] = useState("individual");
  const [rangeGifts, setRangeGifts] = useState(MONEY_RANGES[0]);
  const [managePayout, setManagePayout] = useState("self-managed");
  const [countryCode, setCountryCode] = useState({value:'+1', label:'US (+1)'})

  const { mutateAsync, isLoading } = useApply();

  useEffect(() => {

    if (!defaultValues) return;
    const { setValue } = methods;
    setValue('firstName', defaultValues.first_name);
    setValue('lastName', defaultValues.last_name);
    setValue('email', defaultValues.email);
    setValue('brand', defaultValues.brand);
    setValue('phoneNumber', defaultValues.phone_number);
    setValue('whySponsor', defaultValues.why_sponsor);
    setTypePerson(defaultValues.type);
    setRangeGifts(defaultValues.scholarship_range);
    setManagePayout(defaultValues.payout_type);

  }, [defaultValues, methods])

  useEffect(() => {
    if(typePerson !== 'organization'){
      methods.clearErrors(['brand',])
    }
  }, [methods, typePerson])


  async function submitApplyForm(values) {
    const applyFormValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      type: typePerson,
      email: values.email,
      brand: values.brand,
      country_code: countryCode.value,
      phoneNumber: values.phoneNumber,
      whySponsor: values.whySponsor,
      scholarshipRange: rangeGifts,
      payoutType: managePayout,
    };

    const isSubmit = Object.keys(applyFormValues).map((x) => !!x);

    if (isSubmit) {
      await mutateAsync(applyFormValues);
      history.push('/thank-you-page');
    }
  }

  if (isLoading) return <Loader />

  return (

    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submitApplyForm)}
        className="flex flex-col"
      >
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 mr-4">
            <InputField
              disabled={readOnly}
              placeholder="First Name"
              name="firstName"
              type="text"
              registerOptions={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              required
              noDots
            />
            <div
              className="flex flex-row justify-between mb-6"
              style={{ height: "42px" }}
            >
              <div className="flex items-center">
                <label className="text-base flex custom-light-gray">
                  <Radio
                    disabled={readOnly}
                    type="radio"
                    value="individual"
                    checked={typePerson === "individual"}
                    onChange={(e) => setTypePerson(e.target.value)}
                  />
                  <div className="checkmark"></div>
                  Individual
                </label>
              </div>
              <div className="flex items-center">
                <label className="text-base flex custom-light-gray">
                  <Radio
                    type="radio"
                    value="family"
                    disabled={readOnly}
                    checked={typePerson === "family"}
                    onChange={(e) => setTypePerson(e.target.value)}
                  />
                  <div className="checkmark"></div>
                  Family
                </label>
              </div>
              <div className="flex items-center">
                <label className="text-base flex custom-light-gray">
                  <Radio
                    disabled={readOnly}
                    type="radio"
                    value="organization"
                    checked={typePerson === "organization"}
                    onChange={(e) => setTypePerson(e.target.value)}
                  />
                  <div className="checkmark"></div>
                  Organization
                </label>
              </div>
            </div>
            <InputField
              disabled={readOnly}
              placeholder="Email"
              name="email"
              type="email"
              registerOptions={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              required
              noDots

            />
          </div>
          <div className="flex flex-col flex-1 ml-4">
            <InputField
              disabled={readOnly}
              placeholder="Last Name"
              name="lastName"
              type="text"
              registerOptions={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              required
              noDots
            />
            <InputField
              disabled={readOnly}
              placeholder="Name of Organization"
              name="brand"
              type="text"
              registerOptions={typePerson==='organization'?{
                required: {
                  value: true,
                  message: "Name of organization is required",
                }
              }:{
                required: {
                  value: false,
                }
              }}
              required={typePerson==='organization'}
              noDots
            />
            <div
              className="flex flex-row justify-between mb-6 gap-2"
            >
              <SelectField
                className='w-36'
                options={telephone_codes}
                model= {1}
                onChange={(e) => setCountryCode({
                  label: e.label,
                  value: e.value,
                })}
                value={{
                  label: countryCode.label,
                  value: countryCode.value,
                }}
              />
              <div className="flex-auto -m-0.5">
              <InputField
                disabled={readOnly}
                placeholder="Phone Number"
                name="phoneNumber"
                type="tel"
                registerOptions={{
                  required: {
                    value: true,
                    message: "Phone Number is required",
                  },
                }}
                required
                noDots
              />
              </div>
            </div>
          </div>
        </div>
        <TextAreaField
          disabled={readOnly}
          style={{ marginBottom: "0.8rem" }}
          whiteText
          placeholder="Enter here"
          label="Why are you interested in sponsoring Micro Scholarships?"
          name="whySponsor"
          orientation="column"
          registerOptions={{
            required: {
              value: true,
              message: "An explanation is required",
            },
          }}
          required
          noDots
        />
        <div className="text-sm mb-9 text-custom-gray">
          * - These fields are required
        </div>
        <label className="text-sm mb-4 custom-light-gray">
          Range of Annual Scholarship Gifts you are considering:
        </label>
        <div className="grid grid-cols-2">
          {MONEY_RANGES.map((value, index) => (
            <div className="flex items-center mb-3" key={index}>
              <label className="text-base flex custom-light-gray">
                <Radio
                  disabled={readOnly}
                  type="radio"
                  value={value}
                  onChange={(e) => setRangeGifts(e.target.value)}
                  checked={rangeGifts === value}
                />
                <div className="checkmark"></div>
                {value}
              </label>
            </div>
          ))}
        </div>
        <label className="text-sm mb-4 mt-5 custom-light-gray">
          Would you prefer to manage payouts directly with your applicants or
          request Kidcoin's Admin team to support you in that?
        </label>
        <div className="flex mb-9">
          <div className="flex items-center w-52">
            <label className="text-base flex custom-light-gray">
              <Radio
                disabled={readOnly}
                type="radio"
                value="self-managed"
                checked={managePayout === "self-managed"}
                onChange={(e) => setManagePayout(e.target.value)}
              />
              <div className="checkmark"></div>
              Self-Managed
            </label>
          </div>
          <div className="flex items-center">
            <label className="text-base flex custom-light-gray">
              <Radio
                disabled={readOnly}
                type="radio"
                value="kidcoin"
                checked={managePayout === "kidcoin"}
                onChange={(e) => setManagePayout(e.target.value)}
              />
              <div className="checkmark"></div>
              Kidcoin
            </label>
          </div>
        </div>
        {
          !readOnly && (
            <>
              <Button
                type="submit"
                className="bg-custom-yellow text-custom-blue font-bold"
              >
                Submit
              </Button>
              <div
                className="text-custom-white font-museo font-medium mt-3"
                style={{
                  letterSpacing: "0.15px",
                  fontSize: "0.8125rem",
                  lineHeight: "1.4375rem",
                }}
              >
                Already have an account?{" "}
                <Link to="/login" className="text-custom-green">
                  Log In
                </Link>
              </div>
            </>
          )
        }

      </form>
    </FormProvider>
  );
}
