import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { history } from './utils/history';
import LandApp from "./LandApp";
import DashApp from "./DashApp";
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { customQueryClient } from "./utils/queryClient";


export const routes = (
  <QueryClientProvider client={customQueryClient}>
    <Router history={history}>
      <Switch>
        <Route path="/popular" component={LandApp} />
        <Route path="/faq" component={LandApp} />
        <Route path="/apply" component={LandApp} />
        <Route path="/login" component={LandApp} />
        <Route path="/thank-you-page" component={LandApp} />
        <PrivateRoute path="/dashboard/profile" component={DashApp} />
        <PrivateRoute path="/dashboard/profile/edit" component={DashApp} />
        <PrivateRoute path="/dashboard/micros" component={DashApp} />
        <PrivateRoute path="/dashboard/micros/new" component={DashApp} />
        <PrivateRoute path="/dashboard/micros/edit" component={DashApp} />
        <PrivateRoute path="/dashboard/micros/:id" component={DashApp} />
        <PrivateRoute path="/dashboard/kids/chat/:id_chat" component={DashApp} />
        <PrivateRoute path="/dashboard/kids" component={DashApp} />
        <PrivateRoute path="/dashboard/inbox/chat/:id" component={DashApp} />
        <PrivateRoute path="/dashboard/inbox" component={DashApp} />
        <PrivateRoute path="/dashboard/integrations/blendfc" component={DashApp} />

        <PrivateRoute path="/dashboard/sponsors/:id" component={DashApp} />
        <PrivateRoute path="/dashboard/sponsors" component={DashApp} />

        <PrivateRoute path="/dashboard/sponsorApps/:id" component={DashApp} />
        <PrivateRoute path="/dashboard/sponsorApps" component={DashApp} />

        <PrivateRoute path="/dashboard" component={DashApp} />
        <Route path="/" component={LandApp} />
      </Switch>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />

  </QueryClientProvider>
);
