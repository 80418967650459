import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/fonts.css";
import reportWebVitals from "./reportWebVitals";
import { routes } from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

ReactDOM.render(
  <React.StrictMode>
    {routes}
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
