import { ReactComponent as SearchIcon } from "../../../assets/icon/search-icon.svg";

export default function SearchField({ search, setSearch }) {
  return (
    <>
      <div
        className="inline-flex border-transparent rounded-xl w-64 h-8"
        style={{ backgroundColor: "rgba(168, 172, 175, 0.15)" }}
      >
        <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
          <div className="flex">
            <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none px-2 pl-3 pr-2 pt-2 pb-2 whitespace-no-wrap text-grey-dark text-xs">
              <SearchIcon />
            </span>
          </div>
          <input
            type="text"
            className="flex-shrink flex-grow leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none relative focus:outline-none font-semibold text-xs text-black bg-transparent"
            placeholder="Search by transaction"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
