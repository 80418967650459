import { useEffect, useState } from "react";
import styled from "styled-components";
import InputField from "../../../components/InputField/InputField";
import SelectField from "../../../components/SelectField/SelectField";
import TextAreaField from "../../../components/TextAreaField/TextAreaField";
import { Link } from "react-router-dom";
import { ReactComponent as NoImageIcon } from "../../../assets/icon/no-image-icon.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useProfile } from "../../../api/global/hooks";
import { scholarShipRange } from "../dashboardConstants";
import Loader from "../../../components/Loader/Loader";
import { USER_TYPE } from '../../../api/global/constants';
import ModalUpdatePassword from "../../../components/Modals/ModalUpdatePassword";

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 11px 12px;
  display: inline-block;
  min-width: 136px;
  border: 2px solid #465c8b;
`;

const ButtonResetPassword = styled.button`
  width: 100%;
  text-align: left;
`;

const Radio = styled.input`
  display: none;

  & + .checkmark {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(168, 172, 175, 0.5);
    margin-right: 0.75rem;
    position: relative;
  }

  &:checked ~ .checkmark::after {
    display: block;
  }

  & + .checkmark::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #465c8b;
  }
`;



export default function ProfilePage() {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const { data: profile, isLoading } = useProfile();

  const [showModal, setShowModal] = useState(false);
  const [payout, setPayout] = useState(profile?.payout_type);

  useEffect(() => {
    if (!profile) return;
    setPayout(profile.payout_type)
  }, [profile])


  function showForgotPassword() {
    setShowModal(true);
  }

  if (!profile || isLoading) return <Loader />

  return (
    <>
      {showModal && (
        <ModalUpdatePassword showModal={showModal} setShowModal={setShowModal}/>
      )}
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex justify-between">
        <h1 className="text-4xl font-bold">Profile</h1>
        <Button as={Link} to="/dashboard/profile/edit" className="text-sm">
          Edit
        </Button>
      </div>
      <div style={{ borderBottom: "1px solid rgba(151, 151, 151,0.2)" }}>
        <div className="container max-w-4xl p-4">
          <FormProvider {...methods}>
            {!isLoading && (
              <form className="flex flex-row">
                <div className="flex-grow pr-4">
                  <InputField
                    className="fixed-value py-3"
                    placeholder="Name"
                    name="name"
                    type="text"
                    initialValue={`${profile?.first_name ?? ""} ${profile?.last_name ?? ""
                      }`}
                    disabled={true}
                    label="Name"
                    orientation="row"
                    labelOrientation="right"
                  />
                  {
                    profile.type === USER_TYPE.ADMIN && <InputField
                      className="fixed-value py-3"
                      placeholder="Name of Organization"
                      name="nameOrganization"
                      type="text"
                      initialValue={`${profile?.brand ?? ""}`}
                      disabled={true}
                      label="Organization"
                      orientation="row"
                      labelOrientation="right"
                    />
                  }

                  <InputField
                    className="fixed-value py-3"
                    placeholder="Email"
                    name="email"
                    type="email"
                    color="#a8acaf"
                    initialValue={`${profile?.email ?? ""}`}
                    disabled={true}
                    label="Email"
                    orientation="row"
                    labelOrientation="right"
                  />
                  <div className="mb-6 flex flex-row items-center">
                    <label
                      htmlFor="phone"
                      className="font-medium font-museo-sans text-custom-gray text-right w-40 mr-7"
                    >
                      Password
                    </label>
                    <ButtonResetPassword type="button" onClick={showForgotPassword} className="text-xs text-custom-gray underline">
                      Reset password
                    </ButtonResetPassword>
                  </div>
                  <InputField
                    className="fixed-value py-3"
                    placeholder="Phone"
                    name="phone"
                    type="tel"
                    initialValue={`${profile?.phone ?? ""}`}
                    disabled={true}
                    label="Phone Number"
                    orientation="row"
                    labelOrientation="right"
                  />
                  {
                    profile.type === USER_TYPE.ADMIN && (
                      <>

                        <TextAreaField
                          placeholder="Enter here"
                          style={{ marginBottom: "1.5rem" }}
                          label="Why sponsor?"
                          name="whySponsor"
                          orientation="row"
                          disabled={true}
                          rows="5"
                          initialValue={`${profile?.why_sponsor ?? ""}`}
                        />
                        <SelectField
                          options={scholarShipRange}
                          label="Scholarship Range"
                          className="w-full"
                          initialValue={{
                            label: profile.scholarship_range,
                            value: profile.scholarship_range,
                          }}
                          disabled={true}

                        />
                        <p className="text-custom-gray font-museo-sans pl-40 mt-8 mb-4 text-xs">
                          Would you prefer to manage payouts directly with your
                          applicants <br />
                          or request Kidcoin's Admin team to support you in that?
                        </p>
                        <div className="mb-6 flex flex-row items-center">
                          <label
                            htmlFor="phone"
                            className="font-medium font-museo-sans text-custom-gray text-right w-40 mr-7"
                          >
                            Payouts
                          </label>
                          <div className="flex flex-row w-full">
                            <div className="flex items-center mr-3">
                              <label className="text-base flex text-custom-gray">
                                <Radio
                                  type="radio"
                                  value="self-managed"
                                  checked={payout === "self-managed"}
                                  disabled={true}
                                />
                                <div className="checkmark"></div>
                                Self-Managed
                              </label>
                            </div>
                            <div className="flex items-center">
                              <label className="text-base flex text-custom-gray">
                                <Radio
                                  type="radio"
                                  value="kidcoin"
                                  checked={payout === "kidcoin"}
                                  disabled={true}
                                />
                                <div className="checkmark"></div>
                                Kidcoin
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className="w-1/4 pl-4">
                  <div
                    className="w-40 h-40 rounded-xl overflow-hidden flex items-center justify-center"
                    style={
                      profile?.logo !== ""
                        ? {
                          background: `url(${profile?.logo}) no-repeat 50% 50%`,
                          backgroundSize: "cover",
                        }
                        : { backgroundColor: "#eeeeee" }
                    }
                  >
                    {profile?.logo === "" && <NoImageIcon className="-mt-4" />}
                  </div>
                </div>
              </form>
            )}
          </FormProvider>
        </div>
      </div>
    </>
  );
}
