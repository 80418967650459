import { useEffect, useState } from "react";

import styled from "styled-components";
import InputField from "../../../components/InputField/InputField";
import SelectField from "../../../components/SelectField/SelectField";
import TextAreaField from "../../../components/TextAreaField/TextAreaField";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import ModalPrompt from "../../../components/Modals/ModalPrompt";
import { useProfile, useUpdateProfileMutation } from "../../../api/global/hooks";
import { InputFile } from "../../../components/InputFile/InputFile";
import Loader from "../../../components/Loader/Loader";
import { scholarShipRange } from "../dashboardConstants";
import { USER_TYPE } from '../../../api/global/constants';


const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 11px 12px;
  display: inline-block;
  min-width: 136px;
  border: 2px solid #465c8b;
`;

const ButtonTransparent = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: "transparent";
  border: 2px solid #465c8b;
  color: #465c8b;
  padding: 11px 12px;
  display: inline-block;
  min-width: 136px;
`;

const Radio = styled.input`
  display: none;

  & + .checkmark {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(168, 172, 175, 0.5);
    margin-right: 0.75rem;
    position: relative;
  }

  &:checked ~ .checkmark::after {
    display: block;
  }

  & + .checkmark::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #465c8b;
  }
`;



export default function ProfileEditPage() {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const { getValues } = methods;
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [payout, setPayout] = useState('kidcoin');
  const [scolarShipRangeState, setScholarshipRangeState] = useState('');

  const [image, setImage] = useState(null);
  const { data: profile, isLoading } = useProfile();
  const isSuperAdmin = profile?.type === USER_TYPE.SUPER_ADMIN;
  const { mutate } = useUpdateProfileMutation();


  useEffect(() => {
    if (!profile) return;
    setPayout(profile.payout_type);
    setScholarshipRangeState(profile.scholarship_range);
  }, [profile])

  const showDiscardModal = () => setShowModal(true);
  const showModalUpdate = () => setShowUpdateModal(true);

  const okCallback = () => {
    history.push("/dashboard/profile");
    setShowModal(false);
  }

  const okUpdateCallback = () => {
    setShowUpdateModal(false);

    const bodyFormData = new FormData();
    console.log(getValues())
    for (const [key, value] of Object.entries(getValues())) {
      if (value || value === "") bodyFormData.append(key, value);
    }

    if (image) bodyFormData.append('logo', image);

    if (payout) bodyFormData.append('payout_type', payout);
    if (scolarShipRangeState) bodyFormData.append('scholarship_range', scolarShipRangeState);
    console.log(bodyFormData)
    mutate(bodyFormData);
  }

  if (!profile || isLoading) return <Loader />

  const actionButtons = <div>
    <Button className="text-sm mr-3" onClick={showModalUpdate}>Update</Button>
    <ButtonTransparent onClick={showDiscardModal} className="text-sm bg-transparent">
      Cancel
    </ButtonTransparent>
  </div>

  return (
    <>
      {showModal && (
        <ModalPrompt showModal={showModal} setShowModal={setShowModal} message={'The changes will be discarded...'} okCallback={okCallback} />
      )}

      {showUpdateModal && (
        <ModalPrompt showModal={showUpdateModal} setShowModal={setShowUpdateModal} message={'Would you like to save changes?'} okCallback={okUpdateCallback} />
      )}
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex justify-between">
        <h1 className="text-4xl font-bold">Edit Profile</h1>
        {actionButtons}
      </div>
      <div style={{ borderBottom: "1px solid rgba(151, 151, 151,0.2)" }}>
        <div className="container max-w-4xl p-4">
          {!isLoading && (
            <FormProvider {...methods}>
              <form className="flex flex-row">
                <div className="flex-grow pr-4">
                  <InputField
                    className="fixed-value py-3"
                    placeholder="First Name"
                    name="first_name"
                    type="text"
                    initialValue={profile.first_name}
                    label="First Name"
                    orientation="row"
                    labelOrientation="right"
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      }
                    }}
                  />
                  <InputField
                    className="fixed-value py-3"
                    placeholder="Last Name"
                    name="last_name"
                    type="text"
                    initialValue={profile.last_name}
                    label="Last Name"
                    orientation="row"
                    labelOrientation="right"
                  />
                  <InputField
                    className="fixed-value py-3"
                    placeholder="Email"
                    name="email"
                    type="email"
                    initialValue={profile.email}
                    label="Email"
                    orientation="row"
                    disabled={true}
                    labelOrientation="right"
                    color="#a8acaf"

                  />
                  <InputField
                    disabled
                    className="fixed-value py-3"
                    placeholder="Phone"
                    name="phone"
                    type="tel"
                    initialValue={`${profile.country_code} ${profile.phone}`}
                    label="Phone Number"
                    orientation="row"
                    labelOrientation="right"
                    color="#a8acaf"
                  />

                  {
                    !isSuperAdmin &&
                    <>
                      <InputField
                        className="fixed-value py-3"
                        placeholder="Name of Organization"
                        name="brand"
                        type="text"
                        initialValue={profile.brand}
                        label="Organization"
                        orientation="row"
                        labelOrientation="right"
                      />
                      <TextAreaField
                        placeholder="Enter here"
                        style={{ marginBottom: "1.5rem", color: "#465c8b" }}
                        label="Why sponsor?"
                        name="why_sponsor"
                        orientation="row"
                        rows="5"
                        initialValue={profile.why_sponsor}
                      />
                      <SelectField
                        options={scholarShipRange.map(x => ({ label: x, value: x }))}
                        label="Scholarship Range"
                        className="w-full"
                        value={{
                          value: scolarShipRangeState,
                          label: scolarShipRangeState,
                        }}
                        onChange={e => setScholarshipRangeState(e.value)}
                      />
                      <InputFile
                        name="logo"
                        src={profile.logo}
                        setFile={setImage}
                      />
                      <p className="text-custom-gray font-museo-sans pl-40 mt-8 mb-4 text-xs">
                        Would you prefer to manage payouts directly with your applicants{" "}
                        <br />
                        or request Kidcoin's Admin team to support you in that?
                      </p>
                      <div className="mb-6 flex flex-row items-center">
                        <label
                          htmlFor="phone"
                          className="font-medium font-museo-sans text-custom-gray text-right w-40 mr-7"
                        >
                          Payouts
                        </label>
                        <div className="flex flex-row w-full">
                          <div className="flex items-center mr-3">
                            <label className="text-base flex text-custom-gray">
                              <Radio
                                type="radio"
                                value="self-managed"
                                checked={payout === 'self-managed'}
                                onChange={() => setPayout('self-managed')}
                              />
                              <div className="checkmark"></div>
                              Self-Managed
                            </label>
                          </div>
                          <div className="flex items-center">
                            <label className="text-base flex text-custom-gray">
                              <Radio
                                type="radio"
                                value="kidcoin"
                                checked={payout === 'kidcoin'}
                                onChange={() => setPayout('kidcoin')}
                              />
                              <div className="checkmark"></div>
                              Kidcoin
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>

              </form>
            </FormProvider>
          )}
        </div>
      </div>
      <div className="pl-8 pr-8 pt-10 pb-10 flex justify-end">
        {actionButtons}
      </div>
    </>
  );
}
