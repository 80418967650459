import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import mergeRefs from "react-merge-refs";

const Input = styled.input`
  border-radius: 10px;
  background-color: rgba(168, 172, 175, 0.15);
  font-size: 1rem;
  padding-left: 16px;
  color: #e8e8e8;

  outline: none;
  width: 100%;
  &::placeholder {
    font-size: 1rem;
    line-height: 1;
    color: #a8acaf;
  }
  &.fixed-value {
    color: #465c8b;
  }

  :disabled {
    color: #465c8b;
  }

  /* Change Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: #e8e8e8;
    -webkit-box-shadow: 0 0 0px 1000px rgba(168, 172, 175, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ${(props) =>
    props.color &&
    css`
      /* Change Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid transparent;
        -webkit-text-fill-color: props.color || #e8e8e8;
        -webkit-box-shadow: 0 0 0px 1000px rgba(168, 172, 175, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    `}
`;

const orientations = {
  row: "flex flex-row items-center",
  column: "flex flex-col items-start",
};

const orientations_width = {
  row: "w-40",
  column: "",
}

const labelOrientations = {
  left: "text-left",
  right: "text-right",
};

export default function InputField({
  placeholder,
  name,
  type,
  className,
  color,
  initialValue,
  value,
  disabled,
  label,
  orientation,
  labelOrientation,
  onKeyDown,
  onKeyUp,
  onBlur,
  icon,
  noDots,
  pl,
  fixedPrefixText,
  autoComplete,
  registerOptions,
  onFocus,
  customRef,
  as,
  required = false
}) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { ref: formRef } = register(name, registerOptions);

  return (
    <>
      <div className={`mb-6 relative ${orientations[orientation]}`}>
        {label && (
          <label
            htmlFor={name}
            className={`font-medium font-museo-sans text-custom-gray ${
              labelOrientation
                ? labelOrientations[labelOrientation]
                : labelOrientations.left
            } ${orientations_width[orientation]} mr-7 ${!labelOrientation ? "mb-3" : ""}`}
          >
            {label}
          </label>
        )}
        {fixedPrefixText}
        <Controller
          control={control}
          name={name}
          value={value}
          defaultValue={initialValue}
          render={({ field }) => (
            <Input
              as={as}
              placeholder={`${placeholder ?? ""}${required? '*':''} ${
                noDots === true ? "" : "..."
              }`}
              name={name}
              id={name}
              type={type}
              autoComplete={autoComplete}
              className={`${
                errors?.[name]
                  ? "border border-red-500"
                  : "border border-transparent"
              } py-2 font-bold ${className ?? ""}`}
              style={{ color: color, paddingLeft: pl }}
              aria-invalid={errors?.[name] ? "true" : "false"}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              onFocus={onFocus}
              ref={customRef ? mergeRefs([customRef, formRef]) : formRef}
              {...field}
            />
          )}
        />
        {icon}
        {errors?.[name] && (
          <span className="absolute -bottom-5 left-1 text-sm text-red-500">
            {errors?.[name].message}
          </span>
        )}
      </div>
    </>
  );
}
