import { Route, Switch } from "react-router";
import AboutPage from "../../../pages/Dashboard/MainPage/AboutPage";
import ApplyPage from "../../../pages/Dashboard/MainPage/ApplyPage";
import LoginPage from "../../../pages/Dashboard/MainPage/LoginPage";
import ThankYouPage from "../../../pages/Dashboard/MainPage/ThankYouPage";
import FaqPage from "../../../pages/Dashboard/MainPage/FaqPage";
import PopularPage from "../../../pages/Dashboard/MainPage/PopularPage";

export default function AppContent() {

  // TODO!: Rewrite routing
  return (
    <main>
      <div className="container max-w-4xl">
        <Switch>
          <Route exact path="/">
            <AboutPage />
          </Route>
          <Route exact path="/apply">
            <ApplyPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/thank-you-page">
            <ThankYouPage />
          </Route>
          <Route exact path="/faq">
            <FaqPage />
          </Route>
          <Route exact path="/popular">
            <PopularPage />
          </Route>
        </Switch>
      </div>
    </main>
  );
}
