export const MICROS_QUERY_INFO = {
  POPULAR_MICROS: '/dashboard/micros/popular',
  GET_ALL_MICROS: '/dashboard/micros',
  GET_MICRO_OVERVIEW: '/dashboard/micros/{microId}/overview',
  GET_MICRO_APPLICATIONS: '/dashboard/micros/{microId}/applications',
  GET_ALL_APPLICATIONS: '/dashboard/micros/applications?limit=2500',
  GET_LOCATION_MICROS: '/locations/?q={query}',

};

export const MICROS_MUTATION_INFO = {
  UPDATE_APPLICATIONS_STATUS: '/dashboard/micros/applications/action',
  CREATE_MICROS: '/dashboard/micros',
  DELETE_MICRO: '/dashboard/micros/{microId}',
  UPDATE_MICROS: '/dashboard/micros/{microId}',
  UPDATE_MICRO_SATUS: '/dashboard/micros/{microId}/action',

};

