import Select from "react-select";

export default function SelectField({
  options,
  name,
  disabled,
  label,
  initialValue,
  className,
  alignOptions,
  onChange,
  onBlur,
  value,
  model = 0
}) {


  const customStyles = {
    control: (base, state) => ({
      ...base,
      marginTop: -2,
      background: "rgba(168, 172, 175, 0.15)",
      borderRadius: 10,
      borderColor: state.isFocused ? "transparent" : "transparent",
      fontWeight: 600,
      padding: "2px 0px",
      fontSize: "1rem",
      color: "#465c8b",
      cursor: "pointer",
      "&:hover": {
        borderColor: state.isFocused ? "transparent" : "transparent",
      },
    }),
    menu: (base) => ({
      ...base,
      fontSize: "1rem",
      marginTop: 6,
      backgroundColor: "#eeeeee",
      color: "white",
    }),
    indicatorSeparator: (base) => ({}),
    dropdownIndicator: (base) => ({
      ...base,
      color: model===0? "#93c178" : "white",
      "&:hover": {
        color: "#93c178",
      },
    }),
    menuList: (base) => ({
      ...base,
      padding: 10,
      overflowX: "hidden",
      borderRadius: 10,
      fontWeight: 600,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: "#eeeeee",
      padding: 10,
      textAlign: alignOptions,
      color: "#465c8b",
      borderBottom: "1px solid rgba(151, 151, 151, 0.2)",
      "&:hover": {
        color: "#93c178",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = 1;
      const transition = "opacity 300ms";
      const color = model===0? "#465c8b" : "white";

      return { ...provided, opacity, transition, color};
    },
  };

  return (
    <div className="flex">
      {label && (
        <label
          htmlFor={name}
          className={`font-medium font-museo-sans text-custom-gray text-right w-40 mr-7 leading-5`}
        >
          {label}
        </label>
      )}
      <Select
        styles={customStyles}
        options={options}
        className={className}
        isDisabled={disabled}
        defaultValue={initialValue}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    </div>
  );
}
