import { useMemo } from 'react';
import { useAllApplicationsQuery } from '../api/micros/hooks.queries';
import { getAge, nameFromLocation } from '../utils/index';

export function useGetKids() {
  const { data: applications, isLoading } = useAllApplicationsQuery();

  
  const kids = useMemo(() => {
    if (!applications) return []

    return applications.reduce((prev, value) => {
      const index = prev.findIndex(el => el.id === value.user.id);

      if (index === -1) {
        return [...prev, {
          id: value.user.id,
          name: value.user.first_name + " " + value.user.last_name,
          age: getAge(value.user.birthday),
          location: nameFromLocation(value.user.location),
          micros: 1,
        }];
      } else {
        prev[index].micros++;
        return prev;
      }

    }, [])
  }, [applications]);

  return {
    isLoading,
    kids,
  }

}