import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_HOST;

function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return {
      Authorization: `JWT ${user.token}`,
      Version: `kidcoin-web/0.9.1 api/1.0.0`
    };
  } else {
    return {
      Version: `kidcoin-web/0.9.1 api/1.0.0`
    };
  }
}


export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: authHeader(),
});