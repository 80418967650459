import { useEffect, useRef } from "react";

export default function ModalBasicInfo({
  showModal,
  setShowModal,
  message,
  hideClose,
}) {
  const modalRef = useRef();

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  useOutsideClick(modalRef, () => {
    setShowModal((state) => !state);
  });

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto`}
      aria-labelledby="reset-password"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-custom-gray bg-opacity-60 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-custom-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full"
          ref={modalRef}
        >
          <div className="bg-custom-white px-9 pt-6">
            <div className="">
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <h3
                  className="text-base text-center font-avenir-next text-custom-blue leading-6 mb-6"
                  id="modal-title"
                >
                  {message}
                </h3>
                <div className="w-full text-center">
                  {!hideClose && (
                    <button
                      className="m-3 justify-center rounded-3xl border border-custom-blue shadow-sm px-8 py-2 bg-transparent text-base font-medium text-custom-blue focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm flex-1 leading-none cursor-pointer"
                      onClick={() => setShowModal(!showModal)}
                    >
                      ok
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
