import { useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/icon/close-icon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icon/location-icon.svg";
import { ReactComponent as NoImageIcon } from "../../../assets/icon/no-image-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icon/info-icon.svg";
import { FILTERS_LABELS } from "../InboxPage/inboxConstants";
import InputField from "../../../components/InputField/InputField";
import TextAreaField from "../../../components/TextAreaField/TextAreaField";
import SelectField from "../../../components/SelectField/SelectField";
import DatePickerField from "../../../components/DatePickerField/DatePickerField";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router";
import moment from "moment";
import ModalPrompt from "../../../components/Modals/ModalPrompt";
import { useMicroLocationsLazyQuery } from "../../../api/micros/hooks.queries";
import { useCreateMicrosMutation } from "../../../api/micros/hooks.mutations";
import { classesMeta } from "../../../styles";
import { useImage } from "../../../hooks/useImage";
import produce from "immer"
import { useDebounce } from "../../../hooks/useDebounce";
import { ageOptions } from "../dashboardConstants";
import Loader from "../../../components/Loader/Loader";
import { useProfile } from "../../../api/global/hooks";
import { CustomSponsorSelect } from "../../../components/CustomSelect/CustomSponsorSelect";
import { USER_TYPE } from '../../../api/global/constants';
import { calculateTotalReward } from './helpers';
import NumberFormat from 'react-number-format';
import ModalBasicInfo from "../../../components/Modals/ModalBasicInfo";

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 13px 12px;
  height: 100%;
  min-width: 150px;
  display: inline-block;
  padding: 0 1.375rem;
  ${(props) =>
    props.primary === "green" &&
    css`
      background-color: #93c178;
    `}
`;

const Radio = styled.input`
  display: none;

  & + .checkmark {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(168, 172, 175, 0.5);
    margin-right: 0.75rem;
    position: relative;
  }

  &:checked ~ .checkmark::after {
    display: block;
  }

  & + .checkmark::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #465c8b;
  }
`;

export default function MicrosNewPage() {

  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });



  const [microData, setMicroData] = useState({
    tags: [],
    locations: [],
    genderType: "any",
    ageMin: 0,
    ageMax: 0,
    applyByDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    payoutByDate: new Date(),
  });

  const { control, getValues, trigger, watch, setValue} = methods;

  const [sponsor, setSponsor] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [helpMessage, setHelpMessage] = useState('')
  const [showHelpModal, setShowHelpModal] = useState(false)

  const locationObservable = useWatch({ control: control, name: "location", defaultValue: '' });
  const maxCount = watch("maxCount");
  const payoutUsd = watch("payoutUsd");
  const locationDebounced = useDebounce(locationObservable, 350);

  const { imageLink, input, openInput, image } = useImage();
  const { data: locations, refetch, isLoading: isLoadingLocation } = useMicroLocationsLazyQuery(locationDebounced);

  const { data: profile } = useProfile();
  const isSuperAdmin = profile?.type === USER_TYPE.SUPER_ADMIN

  const { mutate: createMicros, isLoading } = useCreateMicrosMutation();
  useEffect(() => locationDebounced && refetch(), [locationDebounced, refetch]);

  useMemo(() => {
  const totalReward = calculateTotalReward(maxCount, payoutUsd);
  setValue('totalReward', totalReward);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxCount, payoutUsd]);

  function handleTagChange(event) {
    if (!(event.key === "Enter") || !event.target.value) return;

    setMicroData(produce(microData, draft => {
      draft.tags.push(event.target.value);
    }));
    event.target.value = ""
    methods.resetField("tags");
  }


  function deleteTag(index) {
    setMicroData(produce(microData, draft => {
      draft.tags.splice(index, 1);
    }))
  }

  function handleLocationChange(location) {
    setMicroData(produce(microData, draft => {
      draft.locations.push(location);
    }));
    methods.resetField("location");
  }

  const deleteLocation = (index) => {
    setMicroData(produce(microData, draft => {
      draft.locations.splice(index, 1);
    }))
  }

  const submitMicros = async (status) => {
    if (!(await trigger())) return;

    const formData = new FormData();
    const values = getValues();

    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('date_application_end', moment(microData.applyByDate).toISOString());
    formData.append('date_start', moment(microData.startDate).toISOString());
    formData.append('date_end', moment(microData.endDate).toISOString());
    formData.append('payout_by', moment(microData.payoutByDate).toISOString());
    formData.append('reward', values.payout);
    formData.append('payout_usd', values.payoutUsd);
    formData.append('min_age', microData.ageMin);
    formData.append('max_age', microData.ageMax);
    formData.append('max_count', values.maxCount);
    formData.append('tags', microData.tags);
    formData.append('location_ids', microData.locations.map(x => x.id));
    formData.append('sex', microData.genderType);
    formData.append('status', status);

    image && formData.append('image', image);

    if (isSuperAdmin) {
      if (!sponsor) alert('Sponsor is not picked')
      else {
        formData.append('sponsor_id', sponsor['value']);
      }

    }

    createMicros(formData)

  }

  const showDiscardModal = () => setShowModal(true);
  const okCallback = () => history.push("/dashboard/micros");

  const showHelpInfo = (message) => {
    setHelpMessage(message);
    setShowHelpModal(true)
  }

  const buttons = (
    <div className="flex items-center justify-center">
      <Button
        className="text-sm mr-3"
        primary="green"
        onClick={() => submitMicros('published')}
      >
        Publish
      </Button>
      <Button className="text-sm mr-3" onClick={() => submitMicros('draft')}>Save Draft</Button>
      <button className="rounded-full bg-custom-blue">
        <CloseIcon
          className="p-3 fill-current text-custom-yellow"
          width="38"
          height="38"
          onClick={showDiscardModal}
        />
      </button>
    </div>
  )

  return (
    <>
      {showModal && (
        <ModalPrompt
          showModal={showModal}
          setShowModal={setShowModal}
          message={'All unsaved changes will be discarded. Are you sure you want to proceed?'}
          okCallback={okCallback}
        />
      )}
      {showHelpModal && (
        <ModalBasicInfo
          showModal={showHelpModal}
          setShowModal={setShowHelpModal}
          message={helpMessage}
        />
      )}
      <FormProvider {...methods}>
        <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex justify-between">
          {input}
          {(isLoading || isLoadingLocation) && <Loader />}
          <h1 className="text-4xl font-bold">Create Micro</h1>
          {buttons}
        </div>
        <div>
          <div
            className="border-b bo px-8 py-5 flex justify-between"
            style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
          >
            <h2 className="text-base font-semibold">Create micro</h2>
            <div className={classesMeta["New Micro"]}>New Micro</div>
          </div>

          <div className="container max-w-4xl p-4">
            {
              isSuperAdmin && <div className="max-w-2xl mb-4">
                <CustomSponsorSelect setValue={setSponsor} value={sponsor} />
              </div>
            }
            <div className="flex flex-col">
              <div className="flex">
                <div className="flex-grow pr-4">
                  {/* Name field */}
                  <InputField
                    className="py-3"
                    placeholder="Enter Name for Micro"
                    name="name"
                    type="text"
                    label="Name Micro"
                    orientation="column"
                    color="#465c8b"
                    icon={
                      <InfoIcon
                        className="absolute bottom-4 right-3 h-5 cursor-pointer"
                        onClick={()=> showHelpInfo('Micro = Microscholarship')}
                      />
                    }
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                  />
                </div>
                <div onClick={openInput} className="w-1/6 pl-4 flex flex-col cursor-pointer items-end">
                  <button
                    type="button"
                    className="text-sm text-custom-gray font-museo-sans mb-3 cursor-pointer"
                  >
                    Upload image
                  </button>
                  <div
                    className="w-24 h-24 rounded-xl overflow-hidden flex items-center justify-center"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    {
                      imageLink
                        ? <img src={imageLink} alt="micro-img" />
                        : <NoImageIcon className="-mt-4" />
                    }
                  </div>
                </div>
              </div>
              {/* Description field */}
              <TextAreaField
                placeholder="Enter here"
                style={{ marginBottom: "1rem" }}
                styleWrapper={{ marginTop: "-0.8rem" }}
                label="Description"
                name="description"
                orientation="column"
                rows="5"
                icon={
                  <InfoIcon
                    className="absolute bottom-8 right-3 h-5 cursor-pointer z-10"
                    onClick={()=> showHelpInfo('Micros are awarded to kids based on demonstrating some good habits or achievements. Describe what kids need to do in order to receive the award.')}
                  />
                }
                registerOptions={{
                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
              />
              <div className="flex flex-row">
                <div className="w-1/2 pr-4">
                  {/* Max Recepients field */}
                  <InputField
                    as={NumberFormat}
                    className="py-3"
                    placeholder="Enter here"
                    name="maxCount"
                    type="text"
                    label="Max Recepients"
                    orientation="column"
                    color="#465c8b"
                    icon={
                      <InfoIcon
                        className="absolute bottom-4 right-3 h-5 cursor-pointer"
                        onClick={()=> showHelpInfo('Once the # of Max Recipients is reached, new applications will not be accepted.')}
                      />
                    }
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                  />
                </div>
                <div className="w-1/2 pl-4">
                  <InputField
                    as={NumberFormat}
                    className="py-3"
                    placeholder="Enter here"
                    name="payoutUsd"
                    type="text"
                    label="USD value of Reward"
                    orientation="column"
                    color="#465c8b"
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/2 pr-4">
                  {/* Reward field */}
                  <InputField
                    className="py-3"
                    placeholder="Enter here"
                    name="payout"
                    type="text"
                    label="Reward"
                    orientation="column"
                    color="#465c8b"
                    icon={
                      <InfoIcon
                        className="absolute bottom-4 right-3 h-5 cursor-pointer"
                        onClick={()=> showHelpInfo('Reward could be cash, gift card, an item or experience. Value is typically $100 or less per Reward.')}
                      />
                    }
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                  />
                </div>
                <div className="w-1/2 pl-4">
                <InputField
                    className="py-3"
                    name="totalReward"
                    type="text"
                    label="Total Micros Value (USD)"
                    orientation="column"
                    color="#465c8b"
                    disabled
                  />
                </div>
              </div>
              {/* Tags field */}
              <InputField
                className="py-3"
                placeholder="Enter here"
                name="tags"
                type="text"
                label="Tags"
                orientation="column"
                color="#465c8b"
                onKeyUp={handleTagChange}
                icon={
                  <InfoIcon
                    className="absolute bottom-4 right-3 h-5 cursor-pointer"
                    onClick={()=> showHelpInfo('Help parents and kids find your Micro by categorizing it.')}
                  />
                }
              />
              <div>
                {microData.tags.length > 0 && (
                  <div
                    className="flex items-center flex-wrap"
                    style={{ marginTop: "-0.8rem" }}
                  >
                    {microData.tags.map((tag, index) => (
                      <div
                        className={FILTERS_LABELS["micros"]}
                        style={{ textTransform: "lowercase" }}
                        key={index}
                      >
                        #{tag}
                        <CloseIcon
                          className="fill-current cursor-pointer ml-3"
                          onClick={() => deleteTag(index)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="border-b border-t bo px-8 py-5 mb-6 flex justify-between"
            style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
          >
            <h2 className="text-base font-semibold">Target Audience</h2>
          </div>
          <div className="container max-w-4xl p-4">
            <div className="flex flex-row">
              <div className="mb-6 w-1/2 pr-4 flex flex-col items-start relative">
                <div className="font-medium relative font-museo-sans text-custom-gray text-left w-40 mb-3">
                  Gender
                </div>
                <div className="flex flex-row w-full">
                  <div className="flex items-center mr-3">
                    <label
                      htmlFor="field-male"
                      className="text-base flex text-custom-gray"
                    >
                      <Radio
                        type="radio"
                        value="male"
                        id="field-male"
                        onChange={() => setMicroData({ ...microData, genderType: 'male' })}
                        checked={microData.genderType === "male"}
                      />
                      <div className="checkmark"></div>
                      Boys
                    </label>
                  </div>
                  <div className="flex items-center mr-3">
                    <label
                      htmlFor="field-female"
                      className="text-base flex text-custom-gray"
                    >
                      <Radio
                        type="radio"
                        value="female"
                        id="field-female"
                        onChange={() => setMicroData({ ...microData, genderType: 'female' })}
                        checked={microData.genderType === "female"}
                      />
                      <div className="checkmark"></div>

                      Girls
                    </label>
                  </div>
                  <div className="flex items-center ">
                    <label
                      htmlFor="field-any"
                      className="text-base flex text-custom-gray"
                    >
                      <Radio
                        type="radio"
                        value="any"
                        id="field-any"
                        onChange={() => setMicroData({ ...microData, genderType: 'any' })}
                        checked={microData.genderType === "any"}
                      />
                      <div className="checkmark"></div>
                      Everyone
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-6 w-1/2 pl-4 flex flex-col items-start">
                <div className="font-medium font-museo-sans text-custom-gray text-left w-40 mb-3">
                  Age
                </div>
                <div className="flex flex-row w-full">
                  <div className="flex items-center mr-3">
                    <SelectField
                      options={ageOptions.map(x => ({ label: x, value: x }))}
                      className="w-full mr-3"
                      initialValue={{ label: 0, value: 0 }}
                      alignOptions="center"
                      onChange={(e) => setMicroData({ ...microData, ageMin: e.value })}
                      value={{
                        label: microData.ageMin,
                        value: microData.ageMin,
                      }}
                    />

                    <span className="text-custom-gray mr-3">to </span>
                    <Controller
                      name="age"
                      control={methods.control}
                      defaultValue=""
                      render={({ field: { ref, onChange, ...field } }) => (
                        <SelectField
                          options={ageOptions.map(x => ({ label: x, value: x }))}
                          className="w-full mr-3"
                          initialValue={{ label: 0, value: 0 }}
                          alignOptions="center"
                          onChange={(e) => setMicroData({ ...microData, ageMax: e.value })}
                          value={{
                            label: microData.ageMax,
                            value: microData.ageMax,
                          }}
                        />
                      )}
                    />
                    <span className="text-custom-gray">OR </span>
                    <label className="text-base flex text-custom-gray ml-3">
                      <Radio
                        type="radio"
                        value="any"
                        onChange={() => { setMicroData({ ...microData, ageMax: 0, ageMin: 0 }) }}
                        checked={microData.ageMax === 0 && microData.ageMin === 0}
                      />
                      <div className="checkmark"></div>
                      Any
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-1/2 pr-4 relative">
                <Controller
                  name="location"
                  control={methods.control}
                  defaultValue=""
                  render={({ field: { ref, ...field }, }) => (
                    <InputField
                      {...field}
                      className="py-3 pl-10 pr-10"
                      type="text"
                      orientation="column"
                      pl={"6rem"}
                      color="#465c8b"
                      noDots
                      icon={<LocationIcon className="absolute top-3 right-4" />}
                      fixedPrefixText={
                        <span className="text-custom-gray font-bold absolute top-3 left-4">
                          Location:
                        </span>
                      }
                    />
                  )}
                />
                {locationDebounced &&
                  locations &&
                  locations.length > 0 && (
                    <div
                      className="rounded-lg overflow-hidden absolute top-14 left-0 w-full h-48 overflow-y-scroll z-10"
                      style={{ backgroundColor: "#eeeeee" }}
                    >
                      <ul>
                        {locations.map((location, index) => (
                          <li
                            className="text-custom-gray p-3 font-museo-sans flex flex-col mb-2 cursor-pointer hover:bg-gray-200"
                            key={location.id}
                            tabIndex={index + 1}
                            onClick={() => handleLocationChange(location)}
                          >
                            <span className="text-base block leading-none">
                              {location.name}
                            </span>
                            <span className="text-xs">
                              {location.state_code}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                {microData.locations.length > 0 && (
                  <div
                    className="flex items-center flex-wrap"
                    style={{ marginTop: "-0.8rem" }}
                  >
                    {microData.locations.map((location, index) => (
                      <div
                        className={FILTERS_LABELS["micros"]}
                        style={{ textTransform: "capitalize" }}
                        key={location.id}
                      >
                        {location.name}, {location.state_code}
                        <CloseIcon
                          className="fill-current cursor-pointer ml-3"
                          onClick={() => deleteLocation(index)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-1/2 pl-4">
                <span className="font-museo-sans text-sm leading-none block mt-2 text-custom-gray">
                  Leave blank for targeting nationally in your country.<br/>
                  (Available in US, Canada and UK Only).
                </span>
              </div>
            </div>
          </div>
          <div
            className="border-b border-t bo px-8 py-5 mb-6 mt-6 flex justify-between"
            style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
          >
            <h2 className="text-base font-semibold">Timeline</h2>
          </div>
          <div className="container max-w-4xl p-4">
            <div className="flex flex-row">
              <div className="w-1/2 pr-4">
                <DatePickerField
                  prefixText="Apply by"
                  onDayChange={(selectedDay) => setMicroData({ ...microData, applyByDate: selectedDay })}
                />
                <span className="font-museo-sans text-sm leading-none block mt-2 mb-5 text-custom-gray">
                  Applications close sooner than OR <br /> upon reaching 100
                  applicants
                </span>
              </div>
            </div>
            <div className="flex flex-row mb-7">
              <div className="w-1/2 pr-4">
                <DatePickerField
                  prefixText="Start Date"
                  onDayChange={(selectedDay) => setMicroData({ ...microData, startDate: selectedDay })}
                />
              </div>
              <div className="w-1/2 pl-4">
                <DatePickerField
                  prefixText="End Date"
                  onDayChange={(selectedDay) => setMicroData({ ...microData, endDate: selectedDay })}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-1/2 pr-4">
                <DatePickerField
                  prefixText="Payout by"
                  onDayChange={(selectedDay) => setMicroData({ ...microData, payoutByDate: selectedDay })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pl-8 pr-8 pt-10 pb-10 flex justify-end relative">
          {buttons}
        </div>
      </FormProvider>
    </>
  );
}
