import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { useSponsorDisable, useSponsorEnable, useSponsorsListLazyQuery } from "../../../api/global/hooks";
import { ReactComponent as LeftArrowBlueicon } from "../../../assets/icon/left-arrow-blue-icon.svg";
import { ApplySponsor } from "../../../components/ApplySponsor/ApplySponsor"
import { GreenButton } from "../../../components/Buttons/GreenButton";
import { RedButton } from "../../../components/Buttons/RedButton";
import Loader from "../../../components/Loader/Loader";
import { confirmDialog } from "../../../utils";


export const SponsorPage = () => {

  const { id } = useParams();
  const history = useHistory();
  const { data, isLoading } = useSponsorsListLazyQuery('', -1);

  const { mutateAsync: enableAsync, isLoading: sponsorEnableLoading } = useSponsorEnable(id);

  const { mutateAsync: disableAsync, isLoading: sponsorDisableLoading } = useSponsorDisable(id);


  const sponsor = useMemo(() => {
    if (!data || !id) return {};
    return data.find(x => x.id === parseInt(id));
  }, [data, id]);

  if (isLoading) return <Loader />

  const enable = async () => {
    const shouldAction = await confirmDialog({
      title: 'Sponsor action',
      message: 'Are you sure to enable ?',
      OK_MESSAGE: 'Enable'
    });
    if (!shouldAction) return;

    await enableAsync();
    toast('Sponsor has been enabled');
    history.push('/dashboard/sponsors');
  }
  const disable = async () => {
    const shouldAction = await confirmDialog({
      title: 'Sponsor action',
      message: 'Are you sure to disable ?',
      OK_MESSAGE: 'Disable'
    });
    if (!shouldAction) return;

    await disableAsync();
    toast('Sponsor has been disabled');
    history.push('/dashboard/sponsors');
  }



  return (
    <div>
      {(isLoading || sponsorDisableLoading || sponsorEnableLoading) && <Loader />}
      <div className="px-8 py-5 bg-custom-yellow flex">
        <LeftArrowBlueicon onClick={history.goBack} className="mr-4 my-auto  cursor-pointer" />
        <h1 className="text-4xl font-bold">Sponsor</h1>
      </div>
      <div className="mx-5 mt-5">
        <ApplySponsor defaultValues={{ ...sponsor, phone_number: sponsor.phone, type: sponsor.sponsor_type }} readOnly={true} />
      </div>
      <div className="flex w-full mb-5">
        {
          sponsor.disabled
            ? <GreenButton className="w-full mx-3" onClick={enable} >Enable</GreenButton>
            : <RedButton className="w-full mx-3" onClick={disable} >Disable</RedButton>
        }
      </div>
    </div>
  )

}