import { Link } from "react-router-dom";
import styled from "styled-components";
import CardMicros from "../../../components/CardMicros/CardMicros";
import { usePopularMicrosQuery } from "../../../api/micros/hooks.queries";
import Loader from "../../../components/Loader/Loader";

const Button = styled.a`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 13px 12px;
  display: inline-block;
  min-width: 136px;
`;

export default function AboutPage() {

  const { data: popularMicros, isLoading } = usePopularMicrosQuery();

  if (isLoading) return <Loader />

  return (
    <div
      className="ml-auto mt-11 font-museo-sans-700"
      style={{ maxWidth: "526px" }}
    >
      <h1 className="text-4xl mb-6">What are Micro Scholarships?</h1>
      <p
        className="font-museo-sans text-base leading-5"
        style={{ letterSpacing: "0.3px" }}
      >
        A way to engage and motivate children to strive for positive things,
        like eating healthier, exercising and learning new skills. You (your
        brand) decides on the achievement or action that you want to
        incentivize, which kids can apply, and how much to payout for those who
        fulfill the criteria.
      </p>
      <Button as={Link} to="/apply" className="mt-8">
        Get Started by applying to be a Micros Sponsor {">"}
      </Button>
      <h2 className="text-2xl mt-16 mb-4" style={{ letterSpacing: "0.46px" }}>
        Currently Trending Micros & Sponsors
      </h2>
      <div style={{ minHeight: "270px" }}>
        {popularMicros.slice(0, 2).map((certificate) => (
          <CardMicros data={certificate} key={certificate?.id} />
        ))}
      </div>
      <div className="flex justify-end items-center mt-9 mb-8">
        <h3
          className="mr-4 font-semibold font"
          style={{ letterSpacing: "0.2px", fontSize: "1.0625rem" }}
        >
          Sponsor a Micro Scholarship?
        </h3>
        <Button as={Link} to="/apply">
          Apply
        </Button>
      </div>
    </div>
  );
}
