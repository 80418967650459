import { useEffect, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icon/close-icon.svg";
import TableCheckBox from "../../../components/CheckBox/TableCheckBox";
import SearchField from "../../../components/Dashboard/SearchField/SearchField";
import TableInboxItem from "../../../components/Dashboard/TableInboxItem/TableInboxItem";
import SelectCheckBoxes from "../../../components/SelectCheckBoxes/SelectCheckBoxes";
import {
  FILTERS_LABELS,
  INBOX_TABLE_HEADS,
  SELECT_STATES,
  STATUS_FILTER_LIST,
} from "./inboxConstants";
import { capitalize, nameFromLocation, getAge } from '../../../utils/index';
import { useSortableData } from "../../../hooks/useSortableData";
import { TableHead } from "../../../components/Tables/TableHead/TableHead";
import { ApplicationAction } from "../../../components/ApplicationAction/ApplicationAction";
import SendMessageModal from "../../../components/Modals/SendMessageModal";
import { generateFilterLabels } from './helpers'

export default function MicroInbox({ applications }) {

  const applicationsData = useMemo(() => {
    return applications.map(item => ({
      id: item.id,
      name: item.micro.name,
      brand: item.micro.brand,
      applicant: item.user.first_name,
      age: getAge(item.user.birthday),
      location: nameFromLocation(item.user.location),
      status: capitalize(item.status),
      choosen: false,
      microId: item.micro.id,
      gender: item.user.gender,
      allowed_actions: item.allowed_actions,
      uuid: item.uuid,
      creator: {
        first_name: item.micro.creator.first_name,
        last_name: item.micro.creator.last_name
      }
    }));
  }, [applications])

  const { items, requestSort, sortConfig } = useSortableData(applicationsData);
  const [tabActive, setTabActive] = useState(1);
  const [microsFilterList, setMicrosFilterList] = useState(null);
  const [sponsorFilterList, setSponsorFilterList] = useState(null);
  const [search, setSearch] = useState('');

  const [statusFilterList, setStatusFilterList] = useState(STATUS_FILTER_LIST);
  const [filteredInbox, setFilteredInbox] = useState(items);
  const [sendMessageDialog, setSendMessageDialog] = useState(false);

  useEffect(() => {
    const uniqueFilterList = [
      ...new Set(
        applicationsData.map((item) => {
          return item.name;
        })
      ),
    ];

    const filterList = uniqueFilterList.map((item, index) => {
      return { label: item, active: false, id: index };
    });

    setMicrosFilterList(filterList);

  }, [applicationsData]);

  useEffect(() => {
    const uniqueFilterList = [
      ...new Set(
        applicationsData.map((item) => {
          return item.brand;
        })
      ),
    ];

    const filterList = uniqueFilterList.map((item, index) => {
      return { label: item, active: false, id: index };
    });

    setSponsorFilterList(filterList);

  }, [applicationsData]);

  useMemo(() => {

    if (microsFilterList) {
      const activeFilter = [];

      microsFilterList.forEach((item) => {
        if (item.active) {
          activeFilter.push(item.label);
        }
      });

      if (activeFilter.length > 0) {
        setFilteredInbox(() =>
          items.filter((ac) => {
            return activeFilter.includes(ac.name);
          })
        );
      } else {
        setFilteredInbox(items);
      }
    }

    if (sponsorFilterList) {
      const activeFilter = [];

      sponsorFilterList.forEach((item) => {
        if (item.active) {
          activeFilter.push(item.label);
        }
      });

      if (activeFilter.length > 0) {
        setFilteredInbox(() =>
          items.filter((ac) => {
            return activeFilter.includes(ac.brand);
          })
        );
      } else {
        setFilteredInbox(items);
      }
    }

    if (statusFilterList) {
      const activeFilter = [];

      statusFilterList.forEach((item) => {
        if (item.active) {
          activeFilter.push(item.label);
          if (item.nested) {
            item.nested.forEach((nes) => {
              activeFilter.push(nes.label);
            });
          }
        }
        if (item.nested) {
          item.nested.forEach((nes) => {
            if (nes.active) {
              activeFilter.push(nes.label);
            }
          });
        }
      });
      if (activeFilter.length > 0) {
        if (activeFilter.length > 0) {
          setFilteredInbox((state) =>
            state.filter((ac) => {
              return activeFilter.includes(ac.status.toLowerCase());
            })
          );
        } else {
          setFilteredInbox(items);
        }
      }
    }

    if (search) {
      setFilteredInbox(prev => {
        return prev.filter(i => i.applicant.toLowerCase().includes(search.toLowerCase()));
      });
    }

    if (tabActive === 2) {
      setFilteredInbox(prev => {
        return prev.filter(i => i.status === 'Pending');
      });
    }
    if (tabActive === 3) {
      setFilteredInbox(prev => {
        return prev.filter(i => i.status === 'Approved');
      });
    }

  }, [items, microsFilterList, statusFilterList, search, tabActive, sponsorFilterList]);

  const choosenApplications = useMemo(() => {
    const choosen = filteredInbox.filter(el => el.choosen);

    return { isChoosen: choosen.length > 0, choosen };
  }, [filteredInbox]);

  const typeHeaderChecking = useMemo(() => {

    const object = {};
    const choosenAnyOne = filteredInbox.some((el) => {
      return el.choosen;
    });

    const choosenEveryOne = filteredInbox.every((el) => {
      return el.choosen;
    });

    if (!choosenEveryOne && choosenAnyOne) {
      object.checked = true;
      object.type = "anyOne";
    } else if (choosenEveryOne) {
      object.checked = true;
      object.type = "all";
    } else {
      object.checked = false;
    }

    return object;
  }, [filteredInbox]);

  const filtersLabels = useMemo(() => {
    const labels = generateFilterLabels(microsFilterList, statusFilterList, search, sponsorFilterList);
    return labels;
  }, [microsFilterList, statusFilterList, search, sponsorFilterList]);

  const deleteFilterMicrosLabel = (id) => {
    if (microsFilterList) {
      const tempState = [...microsFilterList];
      tempState[id].active = false;

      setMicrosFilterList(tempState);
    }
  };

  const deleteFilterSponsorLabel = (id) => {
    if (microsFilterList) {
      const tempState = [...sponsorFilterList];
      tempState[id].active = false;

      setSponsorFilterList(tempState);
    }
  };

  const deleteFilterStatusLabel = (id) => {
    if (statusFilterList) {
      const tempState = [...statusFilterList];

      tempState.forEach((status) => {
        if (status.id === id) {
          status.active = false;
          if (status.nested) {
            status.nested.forEach((el) => {
              if (el.id === id) {
                el.active = false;
              }
            });
          }
        } else {
          if (status.nested) {
            status.nested.forEach((el) => {
              if (el.id === id) {
                el.active = false;
              }
            });
          }
        }
      });

      setStatusFilterList(tempState);
    }
  };

  const handlerCheckAll = () => {
    if (typeHeaderChecking.type === "anyOne") {
      const tempState = [...filteredInbox];
      tempState.forEach((item) => {
        return (item.choosen = true);
      });

      setFilteredInbox(tempState);
    } else if (typeHeaderChecking.type === "all") {
      const tempState = [...filteredInbox];
      tempState.forEach((item) => {
        return (item.choosen = false);
      });

      setFilteredInbox(tempState);
    } else {
      const tempState = [...filteredInbox];
      tempState.forEach((item) => {
        return (item.choosen = true);
      });

      setFilteredInbox(tempState);
    }
  };

  const handleFilterByMicros = (indexFilter) => {
    const tempState = [...microsFilterList];
    tempState[indexFilter].active = !tempState[indexFilter].active;

    setMicrosFilterList(tempState);
  };

  const handleFilterBySponsor = (indexFilter) => {
    const tempState = [...sponsorFilterList];
    tempState[indexFilter].active = !tempState[indexFilter].active;

    setSponsorFilterList(tempState);
  };

  const filterByStatus = (id) => {
    const tempState = [...statusFilterList];
    // TODO!: Fix when click nested and then parent status
    tempState.forEach((x) => {
      if (x.id === id) {
        x.active = !x.active;
        if (x.nested) {
          x.nested.forEach((y) => {
            y.active = !y.active;
          });
        }
      } else {
        if (x.nested) {
          x.nested.forEach((y) => {
            if (y.id === id) {
              y.active = !y.active;
              let allActiveNested = x.nested.every((x) => x.active);
              if (allActiveNested) {
                x.active = true;
              } else {
                x.active = false;
              }
            }
          });
        }
      }
    });

    setStatusFilterList(tempState);
  };

  const clearingStatusFilter = () => {
    const tempState = [...statusFilterList];
    tempState.forEach((x) => {
      if (x.active) {
        x.active = false;
      }
      if (x.nested) {
        x.nested.forEach((el) => {
          if (el.active) {
            el.active = false;
          }
        });
      }
    });

    setStatusFilterList(tempState);
  };

  const chooseApplication = (id) => {
    const tempState = [...filteredInbox];
    tempState.forEach((el) => {
      if (el.id === id) {
        el.choosen = !el.choosen;
      }
    });

    setFilteredInbox(tempState);
  };

  const closeSendMessageDialog = () => setSendMessageDialog(false);
  const openSendMessageDialog = () => setSendMessageDialog(true);

  return (
    <>
      <SendMessageModal
        isOpened={sendMessageDialog}
        close={closeSendMessageDialog}
        selectedAppIds={choosenApplications.choosen.map(x => x.uuid)}
      />
      <div
        className="border-b bo pl-8"
        style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
      >
        <button
          className="text-xs text-custom-gray font-museo-sans leading-none py-3 w-20"
          style={tabActive === 1 ? { borderBottom: "3px solid #93c178" } : null}
          onClick={() => setTabActive(1)}
        >
          All
        </button>
        <button
          className="text-xs text-custom-gray font-museo-sans leading-none py-3 w-20"
          style={tabActive === 2 ? { borderBottom: "3px solid #93c178" } : null}
          onClick={() => setTabActive(2)}
        >
          Pending ({applicationsData.filter(x => x.status === 'Pending').length})
        </button>
        <button
          className="text-xs text-custom-gray font-museo-sans leading-none py-3 w-20"
          style={tabActive === 3 ? { borderBottom: "3px solid #93c178" } : null}
          onClick={() => setTabActive(3)}
        >
          Approved ({applicationsData.filter(x => x.status === 'Approved').length})
        </button>
      </div>
      <div className="border-b pl-7 py-4 flex items-center">
        <SearchField search={search} setSearch={setSearch} />
        <SelectCheckBoxes
          className="ml-4 w-24"
          placeholder="Micros"
          selectItems={microsFilterList}
          filterInbox={handleFilterByMicros}
        />
        <SelectCheckBoxes
          className="ml-4 w-24"
          placeholder="Status"
          selectItems={statusFilterList}
          filterInbox={filterByStatus}
          clearing={clearingStatusFilter}
        />
        <SelectCheckBoxes
          className="ml-4 w-24"
          placeholder="Sponsor"
          selectItems={sponsorFilterList}
          filterInbox={handleFilterBySponsor}
        />
      </div>
      {filtersLabels?.isActive && (
        <div className="pl-8 py-2 flex flex-wrap items-center">
          {filtersLabels?.microsLabels.length > 0 &&
            filtersLabels?.microsLabels.map((micro) => (
              <div className={FILTERS_LABELS["micros"]} key={micro.id}>
                Micro: {micro.name}
                <CloseIcon
                  className="fill-current cursor-pointer ml-3"
                  onClick={() => deleteFilterMicrosLabel(micro.id)}
                />
              </div>
            ))}
          {filtersLabels?.statusLabels.length > 0 &&
            filtersLabels?.statusLabels.map((status) => (
              <div className={FILTERS_LABELS["status"]} key={status.id}>
                Status: {status.name}
                <CloseIcon
                  className="fill-current cursor-pointer ml-3"
                  onClick={() => deleteFilterStatusLabel(status.id)}
                />
              </div>
            ))}
          {filtersLabels?.sponsorLabels.length > 0 &&
            filtersLabels?.sponsorLabels.map((micro) => (
              <div className={FILTERS_LABELS["micros"]} key={micro.id}>
                Sponsors: {micro.name}
                <CloseIcon
                  className="fill-current cursor-pointer ml-3"
                  onClick={() => deleteFilterSponsorLabel(micro.id)}
                />
              </div>
            ))}
        </div>
      )}
      <div className="relative">
        {choosenApplications.isChoosen && (
          <div className="ml-16 my-3 top-2">
            <div className="text-xs font-museo-sans text-custom-gray flex items-center">
              <span className="mr-2">
                {choosenApplications.choosen.length} selected
              </span>
              <ApplicationAction applications={choosenApplications.choosen} />
              <div
                onClick={openSendMessageDialog}
                className={SELECT_STATES["Send Message"]}>
                Send message
              </div>
            </div>
          </div>
        )}
        <table className="min-w-full" role="table">
          <thead
            className="border-b"
            style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
          >
            <tr>
              <th className="px-6 py-3 pl-8 pr-4 border-gray-300 text-left text-xs text-grey font-normal cursor-pointer hover:flex font-museo-sans">
                <div className="flex items-center text-custom-gray">
                  <TableCheckBox
                    onChange={(e) => handlerCheckAll()}
                    checked={typeHeaderChecking.checked}
                    typeCheck={typeHeaderChecking.type}
                  />
                </div>
              </th>
              {INBOX_TABLE_HEADS.map(o => <TableHead sortConfig={sortConfig} key={`column-${o.readName}`} requestSort={requestSort} readName={o.readName} sortName={o.sortName} />)}
            </tr>
          </thead>
          <tbody>
            {
              filteredInbox.map(application => (<TableInboxItem
                application={application}
                key={`table-inbox-item-${application.id}`}
                chooseApplication={chooseApplication}
              />))
            }
          </tbody>
        </table>
      </div>
    </>
  );
}
