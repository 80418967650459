export const GLOBAL_QUERY_INFO = {
  FAQ: '/dashboard/faq',
  PROFILE: '/dashboard/auth/profile',
  PUBLIC_PROFILE: '/auth/profile/{uuid}',
  ADMIN_STATISTICS: '/dashboard/statistics?sponsor_id={sponsor_id}',
  SPONSOR_APPLICATIONS: '/dashboard/super-admin/sponsors/applications',
  GET_SPONSORS: '/dashboard/super-admin/sponsors?q={query}&limit={limit}',
};


export const GLOBAL_MUTATION_INFO = {
  LOGIN: '/dashboard/auth/login',
  UPDATE_PROFILE: '/dashboard/auth/profile',
  FORGOT_PASSWORD: '/dashboard/auth/forgotPassword',
  SET_UPDATE_PASSWORD: '/dashboard/auth/changePassword',
  SET_FORGOT_PASSWORD_CODE: '/dashboard/auth/changePassword/code',
  APPLICATION_APPLY: '/dashboard/auth/apply',
  UPLOAD_CHAT_IMAGE: '/images/upload',
  SPONSOR_APPLICATION_DECISION: '/dashboard/super-admin/sponsors/applications/{app_id}/decision',
  SPONSOR_DISABLE: '/dashboard/super-admin/disable/{sponsor_id}',
  SPONSOR_ENABLE: '/dashboard/super-admin/enable/{sponsor_id}',
};

export const USER_TYPE = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin'
}
