import { useMemo, useRef, useState } from "react"

export const useImage = (accept = "image/*") => {
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);

  const imageLink = useMemo(() => {
    if (!image) return null;
    return URL.createObjectURL(image);
  }, [image]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  }

  const input = <input accept={accept} onChange={onImageChange} className="invisible absolute" type="file" ref={inputRef} />

  const openInput = () => inputRef && inputRef.current.click();
  const cleanImage = () => setImage(null);

  return {
    input,
    openInput,
    cleanImage,
    imageLink,
    image,
  }
}