import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { apiClient } from '..';
import { MICROS_MUTATION_INFO, MICROS_QUERY_INFO } from './constants';

export const useUpdateApplicationsStatusesMutation = () => {
  const client = useQueryClient();

  return useMutation(
    async ({ applications, newStatus }) => {
      const res = await apiClient.post(MICROS_MUTATION_INFO.UPDATE_APPLICATIONS_STATUS, {
        application_ids: applications.map(i => i.id),
        status: newStatus.toLowerCase(),
      });
      return res.data;
    },
    {
      onSuccess: async (_, { applications }) => {

        for (const application of applications) {
          await client.invalidateQueries(MICROS_QUERY_INFO.GET_MICRO_OVERVIEW.replace('{microId}', application.microId));
          await client.invalidateQueries(MICROS_QUERY_INFO.GET_MICRO_APPLICATIONS.replace('{microId}', application.microId));
        }
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_ALL_APPLICATIONS);
      },
    }
  )
};

export const useCreateMicrosMutation = () => {
  const client = useQueryClient();
  const history = useHistory();

  return useMutation(
    async (formData) => {

      return (await apiClient.post(MICROS_MUTATION_INFO.CREATE_MICROS, formData))
    },
    {
      onSuccess: async () => {
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_ALL_MICROS);
        history.push('/dashboard/micros');
      }
    }
  )
}

export const useDeleteMicro = () => {
  const client = useQueryClient();
  const history = useHistory();

  return useMutation(
    async (microId) => await (apiClient.delete(MICROS_MUTATION_INFO.DELETE_MICRO.replace('{microId}', microId))),
    {
      onSuccess: async () => {
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_ALL_MICROS);

        history.push('/dashboard/micros');
      }
    }
  )

}

export const useUpdateMicros = () => {
  const client = useQueryClient();
  const history = useHistory();

  return useMutation(
    async ({ microId, data }) => await (apiClient.patch(MICROS_MUTATION_INFO.DELETE_MICRO.replace('{microId}', microId), data)),
    {
      onSuccess: async (_, { microId }) => {
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_ALL_MICROS);
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_MICRO_OVERVIEW.replace('{microId}', microId));
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_MICRO_APPLICATIONS.replace('{microId}', microId));
        history.push(`/dashboard/micros/${microId}`);
      }
    }
  )

}

export const useUpdateMicro = () => {
  const client = useQueryClient();

  return useMutation(
    async ({ microId, data }) => await (apiClient.post(MICROS_MUTATION_INFO.UPDATE_MICRO_SATUS.replace('{microId}', microId), data)),
    {
      onSuccess: async (_, { microId }) => {
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_ALL_MICROS);
        await client.invalidateQueries(MICROS_QUERY_INFO.GET_MICRO_OVERVIEW.replace('{microId}', microId));
      }
    }
  )


}