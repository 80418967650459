import { useFormContext } from 'react-hook-form';
import styled from "styled-components";

const TextArea = styled.textarea`
  border-radius: 10px;
  background-color: rgba(168, 172, 175, 0.15);
  outline: none;
  padding: 15px;
  color: ${props => props.whiteText ? '#e8e8e8' : '#465c8b'};
  font-weight: 700;
  &::placeholder {
    font-size: 1rem;
    line-height: 1;
    color: #a8acaf;
  }
  :disabled {
    color : #465c8b;
  }
`;

const orientationsClasses = {
  row: {
    wrapper: "flex flex-row",
    label: "font-medium font-museo-sans text-custom-gray text-right w-40 mr-7",
  },
  column: {
    wrapper: "flex flex-col",
    label: "text-sm mb-3 text-custom-gray",
  },
};

export default function TextAreaField({
  placeholder,
  label,
  name,
  className,
  orientation,
  disabled,
  rows,
  style,
  styleWrapper,
  styleLabel,
  onChange,
  onKeyUp,
  onBlur,
  value,
  registerOptions,
  initialValue,
  whiteText,
  required = false,
  noDots,
  icon
}) {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div
      className={`relative ${orientation
        ? orientationsClasses[orientation]?.wrapper
        : "flex flex-col"
        } ${className}`}
      style={styleWrapper}
    >
      {label && (
        <label
          className={orientationsClasses[orientation]?.label}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <TextArea
        whiteText={whiteText}
        placeholder={`${placeholder}${required? '*': ''}${noDots === true ? "" : "..."}`}
        className={`${errors?.[name] ? "border border-red-500" : "border border-transparent"
          } w-full mb-9 resize-none`}
        style={style}
        name={name}
        id={name}
        aria-invalid={errors?.[name] ? "true" : "false"}
        rows={rows ?? 2}
        disabled={disabled}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        defaultValue={initialValue}
        {...register(name, registerOptions)}
      />
      {icon}
      {errors?.[name] && (
        <span className="absolute -bottom-0.5 left-1 text-sm text-red-500">
          {errors?.[name].message}
        </span>
      )}
      <div className='h-1'>
      </div>
    </div>
  );
}
