import React, { useMemo, useState } from "react";
import { classesMeta } from "../../../styles";
import { useGetKidApplications } from "../../../api/micros/hooks.queries";
import { useParams } from 'react-router-dom';
import Loader from "../../../components/Loader/Loader";
import { capitalize } from "../../../utils";
import Select from 'react-select'
import { Chat } from "../../../components/Chat/Chat";
import { KidData } from "../../../components/Chat/KidData/KidData";

const CustomSelect = props => {
  const app = props.data.app;
  const selectOption = props.selectOption;

  return (
    <div onClick={() => selectOption({ app })}
      className="flex px-5 py-2 mx-3 my-3 bg-gray-100 cursor-pointer" >
      <img className="max-h-11 p-2" src={app.micro.image} alt="logo" />
      <div className={`grid place-items-center ${classesMeta[capitalize(app.status)]}`}>
        {capitalize(app.status)}
      </div>
      <div className="px-5 grid place-items-center text-lg">
        {app.micro.name}
      </div>
    </div>
  );
}

export default function KidsChatPage() {
  const { id } = useParams();
  const { data, isLoading } = useGetKidApplications(id)
  const { applications } = data || {};

  const [selectedApplication, setSelectedApp] = useState(null);

  const options = useMemo(() => {
    if (!applications || applications.length === 0) return [];
    const res = applications.map(app => ({ app }));
    setSelectedApp(applications[0]);
    return res;
  }, [applications]);


  if (isLoading || !selectedApplication) return <Loader />


  return (
    <div className="flex-1 justify-between flex flex-col h-screen">
      <KidData user={selectedApplication.user} />
      <div>
        <div className="py-5 px-10">
          <Select
            components={{ Option: CustomSelect }}
            options={options}
            onChange={item => setSelectedApp(item.app)}
            value={
              {
                value: selectedApplication.id,
                label: selectedApplication.micro.name,
                app: selectedApplication
              }
            }
          />
        </div>
      </div>
      {selectedApplication && <Chat application={selectedApplication} minHeight="22rem" />}
    </div>
  );
}
