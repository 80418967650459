import { FaSortDown, FaSortUp } from 'react-icons/fa';



export function TableHead({ requestSort, sortConfig, sortName, readName }) {

  const getCorrectArrow = () => {

    if (!sortConfig) return <FaSortDown className="ml-1 mb-1" />;
    const { key, direction } = sortConfig;

    if (key === sortName && direction === 'ascending') {
      return <FaSortDown color="green" className="ml-1 mb-1" />;
    }

    if (key === sortName && direction === 'descending') {
      return <FaSortUp color="green" className="ml-1 mb-1" />;
    }

    return <FaSortDown className="ml-1 mb-1" />;
  }

  return <th
    onClick={() => requestSort(sortName)}
    className={`
      table-sort-button 
      select-none
      px-6 py-3 pl-8
    border-gray-300
      text-left text-xs text-grey
      font-normal cursor-pointer
      hover:flex font-museo-sans   
    `}
  >
    <div className="flex items-center text-custom-gray">
      {readName}
      {getCorrectArrow()}
    </div>
  </th>
}