import React, { useState, useRef } from "react";

import "./Accordion.css";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <div className="accordion__section">
      <button
        className={`accordion p-6 pb-0 pl-0 text-xl flex justify-between relative ${setActive}`}
        onClick={toggleAccordion}
      >
        <p className="accordion__title text-custom-white">{props.title}</p>
        {setActive && <div className="text-4xl font-museo absolute" style={{top: 6, right: 27}}>_</div>}
        {!setActive && <div className="text-4xl font-museo-sans">+</div>}
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content overflow-hidden"
      >
        <div
          className="accordion__text mt-7 font-light text-base text-custom-white overflow-hidden"
          dangerouslySetInnerHTML={{ __html: props.content }}
          style={{ lineHeight: "21px", letterSpacing: "0.3px" }}
        />
      </div>
    </div>
  );
}

export default Accordion;
