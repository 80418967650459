import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useImage } from "../../hooks/useImage";
import { Button, TextArea } from "../../pages/Dashboard/Kidspage/kidsConstants";
import ImageModal from "../ImageModal/ImageModal";
import { ReactComponent as ChatUploadIcon } from "../../assets/icon/chat-upload-icon.svg";
import { useProfile, useUploadImage } from "../../api/global/hooks";
import { addChatMessageAsync } from "../../utils/firestore";
import { toast } from 'react-toastify';
import Loader from "../Loader/Loader";

export default function SendMessageModal({
  selectedAppIds,
  isOpened,
  close
}) {

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const { input, cleanImage, imageLink, openInput, image } = useImage();
  const { data: profile } = useProfile();
  const { mutateAsync } = useUploadImage();


  const sendMessages = async () => {
    setLoading(true);
    if (!text) return;

    await Promise.all(selectedAppIds.map(uuid => {
      return addChatMessageAsync(uuid, { message: text, senderId: profile.uuid });
    }));

    setText('');
    toast('Messages have been sent');
    cleanImage();
    setLoading(false);
  }
  const sendImages = async () => {
    setLoading(true);
    if (!image) return;
    const formData = new FormData();
    formData.append('file', image);

    const res = await mutateAsync(formData);

    await Promise.all(selectedAppIds.map(uuid => {
      return addChatMessageAsync(uuid, { message: res.file, senderId: profile.uuid, contentType: 'image' });
    }));

    toast('Images has been sent');
    cleanImage();
    setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      {isOpened ? (
        <>
          {input}
          <ImageModal title="Image message" showModal={!!imageLink} setShowModal={cleanImage} >
            <div>
              <div className="w-screen-md h-96">
                <img src={imageLink} className="max-h-full max-w-full" alt="message img" />
              </div>
              <Button
                className="text-sm py-2 leading-none w-full mt-4"
                primary="blue"
                onClick={sendImages}
              >
                Send image
              </Button>
            </div>
          </ImageModal>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative  my-6 mx-auto max-w-3xl w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Send message
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={close}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">

                  <div className="px-3 py-2 w-auto">
                    <TextArea
                      as={TextareaAutosize}
                      className="w-full resize-none"
                      minRows={4}
                      maxRows={6}
                      autoFocus
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <div className="flex flex-row justify-between items-center">
                      <div className="relative my-1 cursor-pointer">
                        <button onClick={openInput} className=" text-white font-bold py-2 px-4 w-full inline-flex items-center">
                          <ChatUploadIcon />
                          <span className="ml-2 text-custom-gray text-sm">Upload File</span>
                        </button>
                      </div>
                      <Button
                        onClick={sendMessages}
                        className="text-sm py-2 leading-none"
                        primary="green"
                      >
                        Send
                      </Button>

                    </div>
                  </div>


                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={close}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null
      }
    </>
  );
}