import { getAge, nameFromLocation } from "../../../utils"
import { ReactComponent as ProfileBlueIcon } from "../../../assets/icon/profile-blue-icon.svg";

export const KidData = ({ user }) => {
  return (
    <div className="p-3 bg-custom-yellow flex justify-between">
      <div className="flex">
        <h3 className="text-3xl font-bold mr-3">{user.first_name} {user.last_name}, {getAge(user.birthday)} yo</h3>
        <span>{nameFromLocation(user.location)}</span>
      </div>
      <div className="bg-white p-1 rounded-2xl">
        {user.image
          ? <img src={user.image} alt="user-avt" className="h-8 w-auto" />
          : <ProfileBlueIcon />}
      </div>

    </div>
  )
}