export const classesMeta = {
  Published:
    "text-sm font-semibold bg-custom-yellow text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  Completed:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Submitted:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Accepted:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Started:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Stopped:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Approved:
    "text-sm font-semibold bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Draft:
    "text-sm font-semibold bg-custom-yellow text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  Pending:
    "text-sm font-semibold bg-custom-yellow text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  Rejected:
    "text-sm font-semibold bg-custom-red text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Failed:
    "text-sm font-semibold bg-custom-red text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  Paid_out: "text-sm font-semibold bg-custom-blue text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  "Pre-Apply":
    "text-sm font-semibold bg-custom-white text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  Withdrawn:
    "text-sm font-semibold bg-custom-white text-custom-gray text-center w-24 rounded-xl py-1 leading-none",
  "New Micro":
    "text-sm font-semibold bg-custom-yellow text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  Brand:
    "text-sm bg-transparent border border-custom-blue text-custom-blue text-center w-28 rounded-xl py-1 leading-none truncate px-2",
  Name: "text-sm bg-custom-blue text-custom-white text-center w-40 rounded-xl py-1 leading-none",
};
