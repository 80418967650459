import { formatNumber } from "../../../utils";

const borderBottom = {
  borderBottom: "1px solid rgba(151, 151, 151, 0.4)",
};

const borderLeft = {
  borderLeft: "1px solid rgba(151, 151, 151, 0.4)",
};

export default function DashboardCard({ chart }) {
  const { logo, data } = chart;
  return (
    <div className="bg-custom-blue text-custom-white rounded-xl">
      <div
        className="flex flex-row justify-between px-7 pt-5 pb-4"
        style={borderBottom}
      >
        <div className="flex flex-col">
          <span className="text-5xl text-custom-yellow">
            {data.title.value}
          </span>
          <span className="text-sm">{data.title.text}</span>
        </div>
        <img className="object-contain" src={logo} alt={data.title.text} />
      </div>
      <div className="flex flex-row justify-between px-7">
        <div className="flex flex-col flex-1 text-left  pt-5 pb-4">
          <span className="text-4xl text-custom-green">
            {formatNumber(data.left.value)}
          </span>
          <span className="text-sm">{data.left.text}</span>
        </div>
        <div
          className="flex flex-col flex-1 text-right  pt-5 pb-4"
          style={borderLeft}
        >
          <span className="text-4xl">{formatNumber(data.right.value)}</span>
          <span className="text-sm">{data.right.text}</span>
        </div>
      </div>
    </div>
  );
}
