import { useState } from "react";
import { useGetSponsorshipApplications, useSponsorsListLazyQuery } from "../../../api/global/hooks";
import { TableSponsorApplicationItem } from "../../../components/Dashboard/TableSponsorApplicationItem/TableSponsorApplicationItem";
import { TableSponsorItem } from "../../../components/Dashboard/TableSponsorItem/TableSponsorItem";
import Loader from "../../../components/Loader/Loader";
import { TableHead } from "../../../components/Tables/TableHead/TableHead";
import { useSortableData } from "../../../hooks/useSortableData";
import { capitalize } from "../../../utils";
import { SPONSOR_APPLICATIONS_HEADS, SPONSOR_HEADS, SPONSOR_TABS } from "../dashboardConstants";

export const SponsorsPage = () => {
  const { data, isLoading } = useSponsorsListLazyQuery('', -1);
  const { items, requestSort, sortConfig } = useSortableData(data || []);

  const { data: sponsorApps, isLoading: sponsorAppsLoading } = useGetSponsorshipApplications();
  const { items: sponsorAppsItems, requestSort: requestSortSponsorApp, sortConfig: configSponsorApps } = useSortableData(sponsorApps || []);


  const [tabActive, setTabActive] = useState(SPONSOR_TABS[0]);

  if (isLoading || sponsorAppsLoading) return <Loader />

  return (
    <>
      <div
        className="border-b bo pl-8"
        style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
      >

      </div>
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex">
        <h1 className="text-4xl font-bold">{tabActive}</h1>
      </div>
      {
        SPONSOR_TABS.map(tabName => (
          <button
            key={`active-tab-${tabName}`}
            className="text-xs text-custom-gray font-museo-sans leading-none py-3 w-40"
            style={tabActive === tabName ? { borderBottom: "3px solid #93c178" } : null}
            onClick={() => setTabActive(tabName)}
          >
            {capitalize(tabName)}
          </button>
        ))
      }
      {tabActive === SPONSOR_TABS[0] && (
        <table className="min-w-full" role="table">
          <thead className="border-b bo" style={{ borderColor: "rgba(151, 151, 151, 0.2)" }}>
            <tr>
              {SPONSOR_HEADS.map(o => <TableHead sortConfig={sortConfig} key={`column-${o.readName}`} requestSort={requestSort} readName={o.readName} sortName={o.sortName} />)}
            </tr>
          </thead>
          <tbody>
            {items.map((sponsor, index) => {
              return <TableSponsorItem sponsor={sponsor} key={index} />
            })}
          </tbody>
        </table>
      )}
      {tabActive === SPONSOR_TABS[1] && (
        <table className="min-w-full" role="table">
          <thead className="border-b bo" style={{ borderColor: "rgba(151, 151, 151, 0.2)" }}>
            <tr>
              {SPONSOR_APPLICATIONS_HEADS.map(o => <TableHead
                sortConfig={configSponsorApps}
                key={`column-${o.readName}`}
                requestSort={requestSortSponsorApp}
                readName={o.readName}
                sortName={o.sortName}
              />)}
            </tr>
          </thead>
          <tbody>
            {sponsorAppsItems.map((sponsorApplication, index) => {
              return <TableSponsorApplicationItem sponsorApplication={sponsorApplication} key={index} />
            })}
          </tbody>
        </table>
      )}

    </>
  )
}