import { useMemo } from "react";

import PieChart from "react-apexcharts";
import Loader from "../../Loader/Loader";

export default function DashboardPieChart({ chart }) {

  const pieChart = useMemo(() => {
    if (!chart) return null;

    chart.categories = chart.categories.map(cat => cat==='paid_out'? 'paid out': cat)

    return {
      series: chart.data,
      options: {
        stroke: {
          colors:  ["#465c8b"],
          width: 2,
        },
        labels: chart.categories,
        legend: {
          // show: false,
          // floating: true,
          position: "right",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 20,
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        colors: ["#FEB019", "#00E396", "#FF4560", "#13D8AA", "#D4526E", "#0F80F0", "#1BA8AA", "#D4556F", "#008F66"],
        theme: {
          // palette: "palette4",
          mode: "dark",
          // monochrome: {
          //   enabled: true,
          //   color: "#f7ef6a",
          //   shadeTo: "dark",
          //   shadeIntensity: 0.5,
          // },
        },
        plotOptions: {
          pie: {
            size: 500,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: "55%",
              background: "transparent",
            },
          },
        },
        title: {
          text: chart.title,
          offsetX: 15,
          offsetY: 5,
          margin: 15,
        },
        chart: {
          width: 200,
          type: "donut",
          background: "transperant",
        },
        dataLabels: {
          enabled: false,
        },
      },
    }
  }, [chart])

  if (!pieChart) return <Loader />

  return (
    <div id="pie-chart">
      <PieChart
        className="bg-custom-blue text-custom-white rounded-xl"
        options={pieChart.options}
        series={pieChart.series}
        type="donut"
        height="200"
      />
    </div>
  );
}
