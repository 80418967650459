import styled, { css } from 'styled-components';
import { useUpdateMicro } from "../../../api/micros/hooks.mutations";
import Loader from "../../../components/Loader/Loader";

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 13px 12px;
  height: 100%;
  min-width: 150px;
  display: inline-block;
  padding: 0 1.375rem;
  ${(props) =>
    props.primary === "green" &&
    css`
      background-color: #93c178;
    `}
`;

export const ActionMicro = ({ micro }) => {
  const { isLoading, mutate } = useUpdateMicro();

  let button = null;

  if (micro?.status === 'draft') {
    button = <Button
      primary="green"
      onClick={() => {
        mutate({ microId: micro.id, data: { status: 'published' } })
      }}
      className="text-sm mr-3"
    >Publish</Button>;
  }

  return <>
    {isLoading && <Loader />}
    {button}
  </>
}