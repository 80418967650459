import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAllMicrosQuery } from "../../../api/micros/hooks.queries";
import TableMicroItem from "../../../components/Dashboard/TableMicroItem/TableMicroItem";
import Loader from "../../../components/Loader/Loader";
import { TableHead } from "../../../components/Tables/TableHead/TableHead";
import { useSortableData } from "../../../hooks/useSortableData";
import { MICROS_TABLE_HEADS, MICROS_TABS_NAMES } from "../dashboardConstants";
import { capitalize } from '../../../utils/index';

const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  color: #eeeeee;
  border-radius: 21px;
  background-color: #465c8b;
  padding: 13px 12px;
  display: inline-block;
  padding: 0 1.375rem;
  display: flex;
  align-items: center;
`;

export default function MicrosPage() {

  const [tabActive, setTabActive] = useState("all");
  const { data, isLoading } = useAllMicrosQuery();
  const { items, requestSort, sortConfig } = useSortableData(data ?? []);

  const filteredItems = useMemo(() => {
    if (!tabActive || tabActive === 'all') return items;
    return items.filter(i => i.status === tabActive);
  }, [items, tabActive]);

  if (isLoading) return <Loader />


  return (
    <>
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex">
        <h1 className="text-4xl font-bold">Micros</h1>
        <Button as={Link} to="/dashboard/micros/new" className="text-sm ml-10">
          Create a Micro
        </Button>
      </div>
      <div
        className="border-b bo pl-8"
        style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
      >
        {
          MICROS_TABS_NAMES.map(tabName => (
            <button
              key={`active-tab-${tabName}`}
              className="text-xs text-custom-gray font-museo-sans leading-none py-3 w-20"
              style={tabActive === tabName ? { borderBottom: "3px solid #93c178" } : null}
              onClick={() => setTabActive(tabName)}
            >
              {capitalize(tabName)}
            </button>
          ))
        }
      </div>
      <div className="overflow-x-auto max-w-fit">
        <table className="min-w-full" role="table">
          <thead
            className="border-b bo"
            style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
          >
            <tr>
              {MICROS_TABLE_HEADS.map(o => <TableHead sortConfig={sortConfig} key={`column-${o.readName}`} requestSort={requestSort} readName={o.readName} sortName={o.sortName} />)}
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((microItem, index) => <TableMicroItem micro={microItem} key={index} />)}
          </tbody>
        </table>
      </div>
    </>
  );
}
