import { useState } from 'react';
import { useEffect } from "react";

import "./assets/css/land.css";
import AppContent from "./components/App/AppContent/AppContent";
import AppHeaderBar from "./components/App/AppHeaderBar/AppHeaderBar";
function LandApp({ history }) {
  const [isAboutPage, setIsAboutPage] = useState(true);
  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (history.location.pathname === "/") {
      bodyClassList.add("about-page");
      setIsAboutPage(true);
    } else {
      bodyClassList.remove("about-page");
      setIsAboutPage(false);
    }

  }, [history.location.pathname]);

  return (
    <>
      <AppHeaderBar isAboutPage={isAboutPage} />
      <AppContent />
      <AppHeaderBar isFooter isAboutPage={isAboutPage} />
    </>
  );
}

export default LandApp;
