import { useEffect, useRef, useState } from "react";
import InputField from "../InputField/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { useForgotPassword, useForgotPasswordCode } from "../../api/global/hooks";
import { LoaderSpinner } from "../Loader/Loader";

export default function ModalResetPassword({ showModal, setShowModal }) {
  const modalRef = useRef();

  const { mutate: forgotPassword, isLoading: isLoadingGetCode, status:statusGetCode } = useForgotPassword();
  const { mutate: forgotPasswordCode, isLoading, status } = useForgotPasswordCode();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      email: localStorage.getItem('user_email')? JSON.parse(localStorage.getItem('user_email')) : ''
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const [showMessage, setShowMessage] = useState(false);
  const [showInputCode, setInputCode] = useState(false);

  useEffect(() => {
    if (!isLoading && status==='success'){
      setInputCode(false);
      setShowMessage(true);
    }
  }, [isLoading, status])

  useEffect(() => {
    if (!isLoadingGetCode && statusGetCode==='success'){
      setInputCode(true);
    }
  }, [isLoadingGetCode, statusGetCode])


  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  function submitForgotPassword(values) {
    const emailValue = values.email;
    if (emailValue) {
      forgotPassword({ email: emailValue });
    }
  }

  function submitCodeForgotPassword(values) {
    const code = values.code;
    const new_password = values.new_password;
    const repeat_new_password = values.repeat_new_password

    if (code && new_password && repeat_new_password) {

      forgotPasswordCode({
        code,
        new_password,
        repeat_new_password
      })
    }
  }

  useOutsideClick(modalRef, () => {
    setShowModal((state) => !state);
  });

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto`}
      aria-labelledby="reset-password"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-custom-gray bg-opacity-60 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-custom-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full"
          ref={modalRef}
        >
          {showMessage && (
            <div className="bg-custom-white px-9 py-4">
              <div className="sm:mt-0 sm:text-left flex flex-col">
                <h3
                  className="text-base font-avenir-next text-custom-blue leading-6 mb-3"
                  id="modal-title"
                >
                  Your password has been updated. You can now log in.
                </h3>
                <button
                  type="button"
                  className="self-center w-full inline-flex justify-center rounded-3xl border-2 border-custom-blue shadow-sm px-4 py-2 bg-transparent text-base font-medium text-custom-blue focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm flex-1 leading-none"

                  onClick={() => setShowModal(!showModal)}
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {
            isLoading && <LoaderSpinner className="absolute top-1/2 left-1/2 z-10 -mt-8 -ml-8" />
          }

          {showInputCode && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(submitCodeForgotPassword)}>

                <div className="bg-custom-blue text-white px-9 py-6 flex flex-col">
                  <div className="bg-custom-blue text-white">
                    <h3
                      className="text-base font-avenir-next bg-custom-blue text-white leading-6 mb-4"
                      id="modal-title"
                    >
                      Input received code and write you new password:
                    </h3>
                    <InputField
                      placeholder="Code"
                      name="code"
                      type="number"
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                      }}
                    />
                    <InputField
                      placeholder="New Password"
                      name="new_password"
                      type="password"
                      autoComplete='new-password'
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                      }}
                    />

                    <InputField
                      placeholder="Repeat new Password"
                      name="repeat_new_password"
                      type="password"
                      autoComplete='new-password'
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                        validate: (val) => {
                          if (methods.watch('new_password') !== val) {
                            return "Passwords do not match";
                          }}
                      }}
                    />
                  </div>

                  <div className="self-center">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-3xl border-2 bg-custom-blue text-white shadow-sm px-4 py-2 bg-transparent text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm flex-1 leading-none"
                    >
                      Submit
                    </button>

                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-3xl border border-transparent shadow-sm px-4 py-2 bg-custom-blue text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm sm:ml-3 flex-1 leading-none"
                      onClick={() => setShowModal(!showModal)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>)}

          {(!showMessage && !showInputCode) && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(submitForgotPassword)}>
                <div className="bg-custom-blue px-9 py-6 flex flex-col">
                  <h3 className="text-base font-avenir-next text-white leading-6 mb-4">
                  Enter your Email to reset you password
                  </h3>
                  <InputField
                    placeholder="Email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    registerOptions={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    }}
                  />
                  <div className="self-center">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-3xl border-2 text-white shadow-sm px-4 py-2 mt-0 bg-transparent text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm flex-1 leading-none"
                    >
                      Submit
                    </button>

                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-3xl border border-transparent shadow-sm px-4 py-2 mt-0 bg-custom-blue text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm sm:ml-3 flex-1 leading-none"

                      onClick={() => setShowModal(!showModal)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>)}
        </div>
      </div>
    </div>
  );
}
