import { useMemo } from 'react';
import { useGetPurchasesQuery } from '../api/integrations/hooks.queries';

export function useGetPurchases() {
  const { data: transactions, isLoading } = useGetPurchasesQuery();

  const purchases = useMemo(()=>{
    if (isLoading) return [];

    return transactions.map(ts => {
      const time_split = ts.time.substring(0, 10).split('-')
      const sorted_time = [time_split[1], time_split[2], time_split[0]].join('/')

      return{
        id: ts.purchaser.id,
        time: sorted_time,
        code: ts.code,
        name: ts.purchaser.first_name + " " + ts.purchaser.last_name,
        age: ts.purchaser.age,
        product: ts.product.name,
        price: ts.product.price,
        quantity: ts.quantity,
        total: ts.total
      }
    })
  }, [transactions, isLoading])


  return {
    isLoading,
    purchases
  }

}
