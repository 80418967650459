import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from '..';
import { GLOBAL_QUERY_INFO, GLOBAL_MUTATION_INFO } from './constants';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

export const useFaq = () => {
  return useQuery(GLOBAL_QUERY_INFO.FAQ);
}

export const useProfile = ({ isPreAuth = false } = {}) => {
  return useQuery(GLOBAL_QUERY_INFO.PROFILE, {
    retry: isPreAuth ? 1 : 3
  });
}

export const usePublicUserProfile = (uuid) => {
  return useQuery(GLOBAL_QUERY_INFO.PUBLIC_PROFILE.replace('{uuid}', uuid));
}

export const useDashboardStats = (sponsorId) => {
  return useQuery(GLOBAL_QUERY_INFO.ADMIN_STATISTICS.replace('{sponsor_id}', sponsorId));
}

export const useLoginMutation = () => {
  const history = useHistory();

  return useMutation(
    async ({ email, password }) => {
      email = email.toLowerCase()
      localStorage.setItem('user_email', JSON.stringify(email));
      const res = await apiClient.post(GLOBAL_MUTATION_INFO.LOGIN, { email, password });
      return res.data;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        history.push('/dashboard');
        window.location.reload();
      },
      onError: (err) => {
        if (err.response && err.response.status === 403) toast('Wrong email or password', { type: 'error' })
        else {
          console.error(err);
          toast('Unknown error');
        }
      }
    }
  );
};

export const useUpdateProfileMutation = () => {

  const client = useQueryClient();
  const history = useHistory();

  return useMutation(async (data) => {
    await apiClient.patch(GLOBAL_MUTATION_INFO.UPDATE_PROFILE, data);
  },
    {
      onSuccess: async () => {
        await client.invalidateQueries(GLOBAL_QUERY_INFO.PROFILE);
        history.push("/dashboard/profile");
      }
    }
  )
}

export const useForgotPassword = () => {
  return useMutation(
    async (data) => {
      const res = await apiClient.post(GLOBAL_MUTATION_INFO.FORGOT_PASSWORD, data);
      return res.data;
    }
  )
}

export const useUpdatePassword = () => {
  return useMutation(
    async (data) => {
      const res = await apiClient.post(GLOBAL_MUTATION_INFO.SET_UPDATE_PASSWORD, data);
      return res.data;
    }
  )
}

export const useForgotPasswordCode = () => {
  return useMutation(
    async (data) => {
      const res = await apiClient.post(GLOBAL_MUTATION_INFO.SET_FORGOT_PASSWORD_CODE, data);
      return res.data;
    }
  )
}

export const useApply = () => {
  return useMutation(
    async (formValues) => {
      const data = {
        first_name: formValues?.firstName,
        last_name: formValues?.lastName,
        type: formValues?.type,
        email: formValues?.email,
        country_code: formValues?.country_code,
        phone_number: formValues?.phoneNumber,
        why_sponsor: formValues?.whySponsor,
        scholarship_range: formValues?.scholarshipRange,
        payout_type: formValues?.payoutType,
        brand: formValues?.brand,
      };
      return (await apiClient.post(GLOBAL_MUTATION_INFO.APPLICATION_APPLY, data)).data
    }
  )
}


export const useUploadImage = () => {
  return useMutation(async (formValues) => (await apiClient.post(GLOBAL_MUTATION_INFO.UPLOAD_CHAT_IMAGE, formValues)).data)
}

export const useGetSponsorshipApplications = () => {
  return useQuery(GLOBAL_QUERY_INFO.SPONSOR_APPLICATIONS);
}

export const useSponsorDecision = (appId) => {
  const client = useQueryClient();

  return useMutation(
    async (data) => (await apiClient.post(GLOBAL_MUTATION_INFO.SPONSOR_APPLICATION_DECISION.replace('{app_id}', appId), data)).data,
    {
      onSuccess: async () => {
        await client.invalidateQueries(GLOBAL_QUERY_INFO.SPONSOR_APPLICATIONS);
      }
    }
  )
}

export const useSponsorsListLazyQuery = (query, limit = 15, enabled = true) => {
  return useQuery(GLOBAL_QUERY_INFO.GET_SPONSORS.replace('{query}', query).replace('{limit}', limit), { enabled });
}


export const useSponsorDisable = (id) => {
  const client = useQueryClient();

  return useMutation(
    async () => (await apiClient.post(GLOBAL_MUTATION_INFO.SPONSOR_DISABLE.replace('{sponsor_id}', id))).data,
    {
      onSuccess: async () => {
        await client.invalidateQueries(GLOBAL_QUERY_INFO.GET_SPONSORS.replace('{query}', '').replace('{limit}', '-1'));
      }
    }
  )

}



export const useSponsorEnable = (id) => {
  const client = useQueryClient();

  return useMutation(
    async () => (await apiClient.post(GLOBAL_MUTATION_INFO.SPONSOR_ENABLE.replace('{sponsor_id}', id))).data,
    {
      onSuccess: async () => {
        await client.invalidateQueries(GLOBAL_QUERY_INFO.GET_SPONSORS.replace('{query}', '').replace('{limit}', '-1'));
      }
    }
  )
}
