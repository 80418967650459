import { apiClient } from '../api';
import { history } from '../utils/history';
import { toast } from 'react-toastify';
import { QueryClient } from 'react-query';

const defaultQueryFn = async (input) => {
  const { queryKey } = input;
  const { data } = await apiClient.get(queryKey[0]);
  return data;
};

const onError = (err) => {
  if (err.response) {
    if (err.response.status === 403) {
      localStorage.clear();
      history.push('/login')
    }
    else {
      if(err.response.data.message.includes(':')){
        err.response.data.message = err.response.data.message.split(':')[1]
      }
      toast(`${err.response.data.message}`, { type: 'error' });
    }
  } else {
    toast(err);
  }
}

export const customQueryClient = new QueryClient({

  defaultOptions: {
    mutations: { onError },
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 120 * 1000,
      queryFn: defaultQueryFn,
      onError: onError
    },
  }
});
