import { useHistory } from "react-router";

export const TableSponsorItem = ({ sponsor }) => {
  const history = useHistory();


  return (
    <tr
      onClick={() => history.push(`/dashboard/sponsors/${sponsor.id}`)}
      key={sponsor.id}
      className="cursor-pointer"
    >
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey w-28"      >
        <div
          className="text-sm text-custom-blue bg-white  text-center w-28 rounded-xl py-1 leading-none  px-2 border-custom-blue border"
        >
          {sponsor.brand}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm w-40"
      >
        <div>
          {sponsor.first_name}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm w-40"
      >
        <div >
          {sponsor.last_name}
        </div>
      </td>
      <td
        valign="middle"
        className={`px-6 py-3 whitespace-no-wrap border-b border-grey text-sm  w-20 ${sponsor.disabled ? 'text-red-500' : 'text-custom-blue'}`}
      >
        {sponsor.disabled ? 'Yes' : 'No'}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-24"
      >
        {sponsor.email}
      </td>
    </tr>
  );
}