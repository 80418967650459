import styled from "styled-components";
import { format } from "date-fns";
import { formatNumber } from "../../utils";

const Card = styled.div`
  border-radius: 10px;
  background-color: #465c8b;
  color: #eeeeee;
  padding: 12px 15px;
  margin-bottom: 13px;
`;

const ChildAge = styled.div`
  border-radius: 10px;
  background-color: #f7ef6a;
`;

export default function CardMicros({ data, style }) {
  return (
    <>
      {data && (
        <div>
          <Card style={style}>
            <div className="flex justify-between">
              <div className="flex flex-col text-left">
                <div className="text-xl leading-none">{data?.brand}</div>
                <div className="text-sm font-museo-sans leading-5 text-custom-yellow mt-2">
                  {data?.name}
                </div>
              </div>
              <div className="flex flex-col text-right">
                <div className="text-xl leading-none">
                  {data?.payout}{" "}
                  <span className="text-custom-green">K-{formatNumber(data?.payout_usd)}</span>
                </div>
                <div className="text-xs font-museo-sans text-custom-yellow leading-5 mt-2">
                  Apply by{" "}
                  {format(
                    new Date(data?.date_application_end ?? null),
                    "MM/dd/yyyy"
                  )}
                </div>
              </div>
            </div>
            <p className="font-avenir-next text-xs mt-3 text-custom-gray truncate">
              {data?.description}
            </p>
            <div className="flex justify-between mt-3">
              <div className="font-museo-sans text-xs text-custom-yellow">
                {data?.tags && data.tags.map((tag) => `#${tag}`)}
              </div>
              <ChildAge className="text-xs font-museo-sans text-custom-blue px-5">
                {data?.age_description}
              </ChildAge>
            </div>
          </Card>
        </div>
      )}
    </>
  );
}
