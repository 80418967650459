import { useFaq } from "../../../api/global/hooks";
import Accordion from "../../../components/Accordion/Accordion";
import Loader from "../../../components/Loader/Loader";

export default function FaqPage() {

  const { data, isLoading } = useFaq();

  if (isLoading) return <Loader />

  return (
    <div className="ml-auto mt-11">
      <h1 className="text-4xl mb-4 font-museo-sans-700">FAQs</h1>
      {data.map((faq, index) => (<Accordion title={faq?.question} content={faq?.response} key={index} />))}
    </div>
  );
}
