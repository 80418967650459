import { useQuery } from 'react-query';
import { MICROS_QUERY_INFO } from './constants';

export const usePopularMicrosQuery = () => {
  return useQuery(MICROS_QUERY_INFO.POPULAR_MICROS);
}
export const useAllMicrosQuery = () => {
  return useQuery(MICROS_QUERY_INFO.GET_ALL_MICROS);
}

export const useMicroOverviewQuery = (microId) => {
  return useQuery(microId && MICROS_QUERY_INFO.GET_MICRO_OVERVIEW.replace('{microId}', microId));
}

export const useMicroApplicationsQuery = (microId) => {
  return useQuery(microId && MICROS_QUERY_INFO.GET_MICRO_APPLICATIONS.replace('{microId}', microId));
}

export const useAllApplicationsQuery = () => {
  return useQuery(MICROS_QUERY_INFO.GET_ALL_APPLICATIONS);
}

export const useMicroLocationsLazyQuery = (query) => {
  return useQuery(MICROS_QUERY_INFO.GET_LOCATION_MICROS.replace('{query}', query), { enabled: true });
}



export const useGetKidApplications = (kidId) => {

  return useQuery(MICROS_QUERY_INFO.GET_ALL_APPLICATIONS, {
    select: (data) => {
      if (!kidId) return null;
      const applications = data.filter(item => item.user.id === parseInt(kidId));

      return {
        applications,
        kid: applications[0].user,
      }
    }
  });
}