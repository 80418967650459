import { useHistory } from "react-router";

export const TableSponsorApplicationItem = ({ sponsorApplication }) => {
  const history = useHistory();

  return (
    <tr
      onClick={() => history.push(`/dashboard/sponsorApps/${sponsorApplication.id}`)}
      key={sponsorApplication.id}
      className="cursor-pointer"
    >
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey w-28"      >
        <div
          className="text-sm text-custom-blue bg-white  text-center w-28 rounded-xl py-1 leading-none  px-2 border-custom-blue border"
        >
          {sponsorApplication.brand}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm w-40"
      >
        <div>
          {sponsorApplication.first_name}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm w-40"
      >
        <div >
          {sponsorApplication.last_name}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-green w-20"
      >
        {sponsorApplication.type}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-24"
      >
        {sponsorApplication.email}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-24"
      >
        {sponsorApplication.payout_type}
      </td>
    </tr>
  );
}