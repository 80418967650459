import { useDashboardStats, useProfile } from "../../api/global/hooks";
import Loader from "../../components/Loader/Loader";
import { ChartFactory } from "../../components/Dashboard/ChartFactory/ChartFactory";
import { CustomSponsorSelect } from "../../components/CustomSelect/CustomSponsorSelect";
import { useState } from "react";
import { USER_TYPE } from '../../api/global/constants';

const ALL = { value: '', label: 'All sponsors' }

export default function DashboardPage() {

  const [sponsor, setSponsor] = useState(ALL);
  const { data: stats } = useDashboardStats(sponsor.value);
  const { data: profile } = useProfile();
  if (!stats || !profile) return <Loader />

  return (
    <>
      <div className="pl-8 pt-10 pb-10 bg-custom-yellow">
        <h1 className="text-4xl font-bold">Dashboard</h1>
      </div>
      {profile.type === USER_TYPE.SUPER_ADMIN && (
        <div className="text-center m-auto mt-5 max-w-2xl">
          <CustomSponsorSelect value={sponsor} setValue={setSponsor} defaultValue={{ value: 'all', label: 'All sponsors' }} />
        </div>
      )}
      <div className="container max-w-screen-xl p-4 grid grid-cols-3 gap-5">
        {stats.map((item, index) => <ChartFactory key={`${index}-${item.type}`} type={item.type} chart={item} />)}
      </div>
    </>
  );
}
