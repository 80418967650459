import { useMemo } from "react";
import { useUpdateApplicationsStatusesMutation } from "../../api/micros/hooks.mutations";
import { SELECT_STATES } from "../../pages/Dashboard/InboxPage/inboxConstants";
import { capitalize, confirmDialog, formatStatus } from "../../utils";
import { getPresentInAllLists } from "../../utils/arrays";
import Loader from "../Loader/Loader";

const ACTION_MAP = {
  'Accepted': 'Accept',
  'Rejected': 'Reject',
  'Approved': 'Approve',
  'Paid_out': 'Mark as "Paid Out"',
  'Failed': 'Mark as "Failed"',
}

const CONFIRMATION_MAP = {
  'accepted': 'Are you sure you want to accept this application?',
  'rejected': 'Are you sure you want to reject this application?',
  'approved': 'Are you sure you want to approve this application?',
  'paid_out': 'Are you sure you want to mark this application as "Paid Out"?',
  'failed':   'Are you sure you want to mark this application as "Failed"?'
}

export const ApplicationAction = ({ applications }) => {

  const { mutate, isLoading } = useUpdateApplicationsStatusesMutation();

  const updateApplicationsStatus = async (newStatus) => {

    let dialog = {
      title: 'Please confirm',
      message: CONFIRMATION_MAP[newStatus],
    }

    const shouldUpdate = await confirmDialog(dialog);

    if (!shouldUpdate) return;

    mutate({
      applications: [...applications],
      newStatus,
    });
  }

  const allowedActions = useMemo(() => {
    if (!applications) return [];
    return getPresentInAllLists(applications.map(x => x.allowed_actions))
  }, [applications]);


  return (
    <>
      {isLoading && <Loader />}
      <div className="flex items-center">
        {allowedActions.map(action => (
          <div
            key={`action-key-${action}`}
            onClick={updateApplicationsStatus.bind(null, action)}
            className={SELECT_STATES[action] + " ml-3"}>
            {ACTION_MAP[capitalize(action)] || formatStatus(action)}
          </div>
        ))}
      </div>
    </>
  )
}
