import moment from "moment";
import { useHistory } from "react-router";
import { classesMeta } from "../../../styles";
import { capitalize, formatNumber } from "../../../utils/index";

export default function TableMicroItem({ micro }) {
  const history = useHistory();

  const redirectToMicro = () => history.push(`/dashboard/micros/${micro.id}`);

  return (
    <tr key={micro.id} onClick={redirectToMicro} className="cursor-pointer">
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey w-28"
      >
        <div
          className="text-sm text-custom-blue bg-white text-center w-28 rounded-xl py-1 leading-none  px-2 border-custom-blue border truncate"
          title={micro.creator.brand? micro.creator.brand: `${micro.creator.first_name} ${micro.creator.last_name}`}
        >
          {micro.creator.brand? micro.creator.brand: `${micro.creator.first_name} ${micro.creator.last_name}`}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey w-28"
      >
        <div
          className="text-sm bg-custom-blue text-custom-white border-b text-center w-28 rounded-xl py-1 leading-none truncate px-2"
          title={micro.name}
        >
          {micro.name}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm w-40"
      >
        <div className={classesMeta[capitalize(micro.status)]}>
          {capitalize(micro.status)}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-green w-20"
      >
        {formatNumber(micro.current_count)}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-24"
      >
        {micro.age_description}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-24"
      >
        {micro.sex}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-green w-24"
      >
        {"K-" + formatNumber(micro.payout_usd)}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-green w-28"
      >
        {"K-" + formatNumber(micro.max_count * micro.payout_usd)}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-36"
      >
        {moment(micro.date_application_end).format("MM/DD/YYYY")}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-48"
      >
        {micro.tags.map((tag) => `#${tag} `)}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 pr-10 whitespace-no-wrap text-left border-b border-grey text-sm "
      ></td>
    </tr>
  );
}
