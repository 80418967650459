import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: #465c8b;
  stroke-width: 4px;
`;
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 3px;
  transition: all 150ms;
  border: 2px solid #eeeeee;
  cursor: pointer;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const TableCheckBox = ({ className, checked, typeCheck, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox defaultChecked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        {(typeCheck === undefined || typeCheck === "all") && (
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        )}
        {typeCheck && typeCheck === "anyOne" && (
          <Icon
            width="8"
            height="2"
            viewBox="0 0 8 2"
            style={{ position: "absolute", top: "38%", left: "20%" }}
          >
            <g>
              <g>
                <path fill="#465c8b" d="M0 0h8v2H0z" />
              </g>
            </g>
          </Icon>
        )}
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
);

export default TableCheckBox;
