import { useMemo, useState } from "react";
import SearchField from '../../../components/Dashboard/SearchField/SearchField';
import TableBlendFCPurchase from "../../../components/Dashboard/TableBlendFCPurchase/TableBlendFCPurchase";
import Loader from "../../../components/Loader/Loader";
import { TableHead } from "../../../components/Tables/TableHead/TableHead";
import { useGetPurchases } from "../../../hooks/useGetPurchases";
import { useSortableData } from "../../../hooks/useSortableData";
import { BLENDFC_TABLE_HEADS } from "./BlendFCConstants";

export default function KidsPage() {

  const { isLoading, purchases } = useGetPurchases();
  const [text, setText] = useState('');
  const { items, requestSort, sortConfig } = useSortableData(purchases);

  const filteredItems = useMemo(() => {
    if (!text) return items;
    return items.filter(item => {
      return (
        item.code.includes(text.toLowerCase()) ||
        item.name.toLowerCase().includes(text.toLowerCase()) ||
        item.product.toLowerCase().includes(text.toLowerCase())
      )
    });
  }, [items, text])

  if (isLoading) return <Loader />

  return (
    <div>
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow">
        <h1 className="text-4xl font-bold">Blend FC</h1>
      </div>
      <div className="border-b bo pl-7 py-4">
        <SearchField search={text} setSearch={setText} />
      </div>
      <table className="min-w-full table-auto">
        <thead
          className="border-b bo"
          style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
        >
          <tr>
            {BLENDFC_TABLE_HEADS.map(head => <TableHead sortConfig={sortConfig} key={`column-${head.readName}`} requestSort={requestSort} readName={head.readName} sortName={head.sortName} />)}
          </tr>
        </thead>
        <tbody>
          {filteredItems.length > 0 &&
            filteredItems.map((purch, index) => {
              return <TableBlendFCPurchase purchase={purch} key={index} />;
            })}
        </tbody>
      </table>
    </div>
  );
}
