import { usePopularMicrosQuery } from "../../../api/micros/hooks.queries";
import CardMicros from "../../../components/CardMicros/CardMicros";
import Loader from "../../../components/Loader/Loader";

export default function PopularPage() {

  const { data, isLoading } = usePopularMicrosQuery();

  if (isLoading) return <Loader />

  return (
    <div className="ml-auto mt-11">
      <h1 className="text-4xl mb-9 font-museo-sans-700">Popular Micros</h1>
      <div className="grid grid-cols-2 gap-3">
        {data.map((certificate) => (
          <CardMicros
            data={certificate}
            key={certificate?.id}
            style={{
              background: "transparent",
              border: "1px solid #eeeeee",
              marginBottom: "0",
            }}
          />
        ))}
      </div>
    </div>
  );
}
