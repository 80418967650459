import { useMemo, useState } from "react";
import SearchField from '../../../components/Dashboard/SearchField/SearchField';
import TableKidsItem from "../../../components/Dashboard/TableKidsItem/TableKidsItem";
import Loader from "../../../components/Loader/Loader";
import { TableHead } from "../../../components/Tables/TableHead/TableHead";
import { useGetKids } from "../../../hooks/useGetKids";
import { useSortableData } from "../../../hooks/useSortableData";
import { KIDS_TABLE_HEADS } from "./kidsConstants";

export default function KidsPage() {

  const { isLoading, kids } = useGetKids();
  const [text, setText] = useState('');
  const { items, requestSort, sortConfig } = useSortableData(kids);

  const filteredItems = useMemo(() => {

    if (!text) return items;
    return items.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));

  }, [items, text])

  if (isLoading) return <Loader />

  return (
    <div>
      <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow">
        <h1 className="text-4xl font-bold">Kids</h1>
      </div>
      <div className="border-b bo pl-7 py-4">
        <SearchField search={text} setSearch={setText} />
      </div>
      <table className="min-w-full table-auto">
        <thead
          className="border-b bo"
          style={{ borderColor: "rgba(151, 151, 151,0.2)" }}
        >
          <tr>
            {KIDS_TABLE_HEADS.map(o => <TableHead sortConfig={sortConfig} key={`column-${o.readName}`} requestSort={requestSort} readName={o.readName} sortName={o.sortName} />)}
          </tr>
        </thead>
        <tbody>
          {filteredItems.length > 0 &&
            filteredItems.map((kidItem, index) => {
              return <TableKidsItem kid={kidItem} key={index} />;
            })}
        </tbody>
      </table>
    </div>
  );
}
