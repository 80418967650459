export default function TableBlendFCPurchase({ purchase }) {

  return (
    <tr
      key={purchase.id}
      className="cursor-pointer"
    >
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey text-custom-blue w-24"
      >
        {purchase.time}
      </td>
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey text-custom-blue w-44"
      >
        {purchase.code}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-64"
      >
        {purchase.name}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-16 pl-10"
      >
        {purchase.age}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-64"
      >
        {purchase.product}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue pl-10"
      >
        {purchase.price}
      </td>
    </tr>
  );
}
