import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.a`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  border-radius: 21px;
  padding: 13px 12px;
  display: inline-block;
  min-width: 265px;
`;

export default function ThankYouPage() {
  return (
    <div className="ml-auto mt-12">
      <h1 className="text-4xl mb-9 font-museo-sans-700">Thank you!</h1>
      <p
        style={{
          letterSpacing: "0.4px",
          fontSize: "21px",
          lineHeight: "1.5rem",
        }}
      >
        We have received your application to become a Micros sponsor.
        <br />
        We will respond shortly.
      </p>
      <Button as={Link} to="/" type="submit" className="bg-custom-yellow text-custom-blue mt-9 font-bold">
        Explore Popular Micros
      </Button>
    </div>
  );
}
