const STATUS_FILTER_LIST = [
  {
    label: "pending",
    value: "pending",
    id: 1,
    active: false,
  },
  {
    label: "pre-pending",
    value: "pre_pending",
    id: 2,
    active: false,
    nested: [
      {
        label: "accepted",
        value: "accepted",
        id: 2.2,
        active: false,
      },
      {
        label: "rejected",
        value: "rejected",
        id: 2.3,
        active: false,
      },
    ],
  },
  {
    label: "active",
    value: "active",
    id: 3,
    active: false,
    nested: [
      {
        label: "started",
        value: "started",
        id: 3.1,
        active: false,
      },
      {
        label: "stopped",
        value: "stopped",
        id: 3.2,
        active: false,
      },
    ]
  },
  {
    label: "submitted",
    value: "submitted",
    id: 4,
    active: false,
    nested: [
      {
        label: "approved",
        value: "approved",
        id: 4.1,
        active: false,
      },
      {
        label: "failed",
        value: "failed",
        id: 4.2,
        active: false,
      },
      {
        label: "paid_out",
        value: "paid_out",
        id: 4.3,
        active: false,
      },
    ],
  },
  {
    label: "withdrawn",
    value: "withdrawn",
    id: 5,
    active: false,
  },
];

const SELECT_STATES = {
  "accepted": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  "rejected": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-yellow text-custom-blue text-center w-24 rounded-xl py-1 leading-none",
  "approved": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-green text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  "failed": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-red text-custom-white text-center w-24 rounded-xl py-1 leading-none",
  "paid_out": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-blue text-custom-white text-center w-28 rounded-xl py-1 leading-none",
  "Send Message": "cursor-pointer mr-2 text-sm font-semibold font-museo bg-custom-blue text-custom-white text-center w-28 rounded-xl py-1 leading-none",
};

const FILTERS_LABELS = {
  "micros": "mr-2 mb-2 text-sm font-museo-sans bg-custom-blue text-custom-white text-center rounded-xl py-1 pr-2 pl-2 leading-none flex items-center justify-between capitalize",
  "status": "mr-2 mb-2 text-sm font-museo-sans bg-custom-yellow text-custom-blue text-center rounded-xl py-1 pr-2 pl-2 leading-none flex items-center justify-between capitalize",
};

const INBOX_TABLE_HEADS = [
  {
    readName: 'Sponsor',
    sortName: 'brand',
  },
  {
    readName: 'Micros',
    sortName: 'name',
  },
  {
    readName: 'Applicant',
    sortName: 'applicant',
  },
  {
    readName: 'Age',
    sortName: 'age',
  },
  {
    readName: 'Gender',
    sortName: 'sex',
  },
  {
    readName: 'Location',
    sortName: 'location',
  },
  {
    readName: 'Status',
    sortName: 'status',
  },
];

export { STATUS_FILTER_LIST, SELECT_STATES, FILTERS_LABELS, INBOX_TABLE_HEADS };
