import { useHistory } from "react-router";
import { classesMeta } from "../../../styles";
import { formatStatus } from "../../../utils";
import TableCheckBox from "../../CheckBox/TableCheckBox";

export default function TableInboxItem({ application, chooseApplication }) {
  const history = useHistory();

  return (
    <tr
      onClick={() => history.push(`/dashboard/inbox/chat/${application.id}`)}
      key={application.id}
      className="cursor-pointer"
    >
      <td
        valign="middle"
        className="px-6 pl-8 pr-4 py-3 whitespace-no-wrap border-b border-grey w-5"
        onClick={(e) => e.stopPropagation()}
      >
        <TableCheckBox
          checked={application.choosen}
          onClick={() => chooseApplication(application.id)}
        />
      </td>
      <td
        valign="middle"
        className="px-6 pl-0 py-3 whitespace-no-wrap border-b border-grey w-28"
      >
        <div className="text-sm text-custom-blue bg-white text-center w-28 rounded-xl py-1 leading-none  px-2 border-custom-blue border">
          {application.brand !== null? application.brand: `${application.creator.first_name} ${application.creator.last_name}`}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 pl-0 py-3 whitespace-no-wrap border-b border-grey w-28"
      >
        <div className="text-sm bg-custom-blue text-custom-white text-center w-24 rounded-xl py-1 leading-none">
          {application.name}
        </div>
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-40"
      >
        {application.applicant}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-20"
      >
        {application.age}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-20"
      >
        {application.gender}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-40"
      >
        {application.location}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm"
      >
        <div className={classesMeta[application.status]}>
          {formatStatus(application.status)}
        </div>
      </td>
    </tr>
  );
}
