import { useEffect, useRef, useState } from "react";
import InputField from "../InputField/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdatePassword } from "../../api/global/hooks";
import { LoaderSpinner } from "../Loader/Loader";

export default function ModalUpdatePassword({ showModal, setShowModal }) {
  const modalRef = useRef();

  const { mutate: updatePassword, isLoading, status } = useUpdatePassword();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const [showMessage, setShowMessage] = useState(false);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if(!isLoading && status ==='success'){
      setShowForm(false)
      setShowMessage(true)
    }
    if(!isLoading && status ==='error'){
      setShowModal(!showModal);
      setShowMessage(false);
      setShowForm(true);
    }
  }, [isLoading, status, showMessage,showModal, setShowModal])

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  function submitCodeForgotPassword(values) {
    const old_password = values.old_password;
    const new_password= values.new_password;
    const repeat_new_password = values.repeat_new_password;

    updatePassword({
      old_password,
      new_password,
      repeat_new_password
    })
  }

  useOutsideClick(modalRef, () => {
    setShowModal((state) => !state);
  });

  function handleConfirChange(){
    setShowModal(!showModal);
    setShowMessage(false);
    setShowForm(true);
  }

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto`}
      aria-labelledby="reset-password"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-custom-gray bg-opacity-60 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="relative inline-block align-bottom bg-custom-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full"
          ref={modalRef}
        >
          {showMessage && (
            <div className="bg-custom-white px-9 pt-6">
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-avenir-next text-custom-blue leading-6 mb-4"
                    id="modal-title"
                  >
                    The password has been updated successfully
                  </h3>
                  <button
                    type="button"
                    className="m-3 w-full inline-flex justify-center rounded-3xl border-2 border-custom-blue shadow-sm px-4 py-2 bg-transparent text-base font-medium text-custom-blue focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm flex-1 leading-none"

                    onClick={handleConfirChange}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {
            isLoading && <LoaderSpinner className="absolute top-1/2 left-1/2 z-10 -mt-8 -ml-8" />
          }


          {showForm && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(submitCodeForgotPassword)}>
                <div className="bg-custom-blue text-white px-9 py-6 flex flex-col">
                  <div className="bg-custom-blue text-white">
                    <h3
                      className="text-base font-avenir-next bg-custom-blue text-white leading-6 mb-4"
                      id="modal-title"
                    >
                      Password Reset:
                    </h3>
                    <InputField
                      placeholder="Old Password"
                      name="old_password"
                      type="password"
                      autoComplete="new-password"
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                      }}
                    />
                    <InputField
                      placeholder="New Password"
                      name="new_password"
                      type="password"
                      autoComplete="new-password"
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                      }}
                    />
                    <InputField
                      placeholder="Repeat new password"
                      name="repeat_new_password"
                      type="password"
                      autoComplete="new-password"
                      registerOptions={{
                        required: {
                          value: true,
                          message: "",
                        },
                        validate: (val) => {
                          if (methods.watch('new_password') !== val) {
                            return "Passwords do not match";
                          }}
                      }}
                    />
                  </div>
                  <div className="self-center">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-3xl border-2 bg-custom-blue text-white shadow-sm px-4 py-2 bg-transparent text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm flex-1 leading-none"
                    >
                      Confirm
                    </button>

                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-3xl border border-transparent shadow-sm px-4 py-2 bg-custom-blue text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm flex-1 leading-none"
                      onClick={() => setShowModal(!showModal)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>)}
        </div>
      </div>
    </div>
  );
}
