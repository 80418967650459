import { classesMeta } from '../../styles';
import { useProfile, usePublicUserProfile } from "../../api/global/hooks";
import ModalImage from "react-modal-image";
import { USER_TYPE } from '../../api/global/constants';

const MessageName = ({ name }) => (
  <div className={classesMeta["Name"] + " mr-2 w-auto px-3"}>
    {name}
  </div>
)

const MessageDate = ({ date }) => (
  <div className="py-1 leading-none px-3 rounded-xl text-custom-white text-sm bg-custom-green">
    {date}
  </div>
)

export default function ChatMessage({ message, micro }) {
  const { data: profile } = useProfile();

  const { content, date, senderID, contentType } = message;
  const { data: publicProfile } = usePublicUserProfile(senderID);

  const isMe = profile.uuid === senderID;

  const side = !isMe ? "items-start" : "items-end";
  const parsedDate = !date ? new Date() : new Date(date['seconds'] * 1000);

  const getSenderName = () => {
    if (!publicProfile) return 'Unknown';
    if (publicProfile.type === USER_TYPE.ADMIN) return `${publicProfile.name}, ${micro.brand}`;
    if (publicProfile.type === USER_TYPE.SUPER_ADMIN) return `${publicProfile.name}, Kidcoin`;
    return publicProfile.name
  }


  return (
    <div className={`py-2 px-5 rounded-lg ${!isMe ? 'bg-gray-200' : 'bg-gray-50'}`} >
      <div >
        <div className={`flex flex-col ${side}`}>
          <div className={`flex flex-col space-y-2 text-xs max-w-lg order-2 ${side}`}>
            <div className="relative">
              {/* <ChatShareIcon className="absolute top-1/2 transform -translate-y-1/2 -right-14 cursor-pointer" /> */}
              {
                contentType === 'text'
                  ? <span className="inline-block text-custom-gray font-bold text-base">{content}</span>
                  : <ModalImage
                    className="h-20 max-w-44"
                    small={content}
                    large={content}
                    alt="chat-img"
                  />
              }
            </div>
          </div>
          <div className="flex justify-between mb-3 w-full">
            {isMe ? <MessageDate date={parsedDate.toLocaleString()} /> : <MessageName name={getSenderName()} />}
            {isMe ? <MessageName name={getSenderName()} /> : <MessageDate date={parsedDate.toLocaleString()} />}
          </div>
        </div>
      </div>

    </div >
  )
}
