import reduce from 'immer';

/**
 * @param {string[][]} values
 */
export const getPresentInAllLists = (values) => {
  let longestList = [];
  let longestListIndex = -1;

  const restCopiedList = reduce(values, draft => {

    draft.forEach((list, i) => {
      if (list.length > longestList.length) {
        longestList = [...list];
        longestListIndex = i;
      }
    })

    draft.splice(longestListIndex, 1);

  });
  const res = [];

  for (const action of longestList) {
    let includes = true;
    for (const actionsList of restCopiedList) {
      if (!(actionsList.includes(action))) {
        includes = false;
      }
    }

    if (includes) res.push(action);
  }

  return res;
}