import { useHistory } from "react-router"
import { classesMeta } from "../../styles";
import { ReactComponent as LeftArrowBlueicon } from "../../assets/icon/left-arrow-blue-icon.svg";
import { ReactComponent as ChatUploadIcon } from "../../assets/icon/chat-upload-icon.svg";
import { capitalize } from "lodash";
import ChatMessage from "../ChatMessage/ChatMessage";
import { Button, TextArea } from "../../pages/Dashboard/Kidspage/kidsConstants";
import { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useImage } from "../../hooks/useImage";
import { useChat } from "../../hooks/useChat";
import { useProfile } from "../../api/global/hooks";
import ImageModal from "../ImageModal/ImageModal";
import Loader from "../Loader/Loader";
import { ApplicationAction } from "../ApplicationAction/ApplicationAction";
import { formatStatus } from "../../utils";

export const Chat = ({ application, minHeight = '30rem' } = {}) => {
  const history = useHistory();
  const [text, setText] = useState('');
  const scrollRef = useRef();
  const { input, cleanImage, imageLink, openInput, image } = useImage();
  const { data: profile } = useProfile();

  const {
    messages,
    sendMessage,
    loaded,
    sendImage,
    isLoading: isChatLoading,
  } = useChat(application ? application.uuid : null);


  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollBy(0, scrollRef.current.scrollHeight);
  }, [loaded])


  const sendNewMessage = () => sendMessage(text, profile.uuid).finally(() => setText(''))
  const sendNewImage = () => sendImage(image, profile.uuid).finally(() => cleanImage())

  if (isChatLoading) return <Loader />

  return (
    <>
      {input}
      <ImageModal title="Image message" showModal={!!imageLink} setShowModal={cleanImage} >
        <div>
          <div className="w-screen-md h-96">
            <img src={imageLink} className="max-h-full max-w-full" alt="message img" />
          </div>
          <Button

            onClick={sendNewImage}
            className="text-sm py-2 leading-none w-full"
            primary="blue"
          >
            Send image
          </Button>
        </div>
      </ImageModal>
      <div
        className="py-3 px-4 flex justify-between items-center"
        style={{ borderBottom: "1px solid rgba(151, 151, 151, 0.4)" }}
      >
        <div className="flex items-center">
          <LeftArrowBlueicon onClick={history.goBack} className="mr-4 cursor-pointer" />
          <div className={classesMeta["Brand"] + " mr-3"}>
            {application.micro.brand !== null?
              application.micro.brand
              :
              `${application.micro.creator.first_name} ${application.micro.creator.last_name}`
            }
          </div>
          <div className={classesMeta["Name"] + " mr-3"}>{application.micro.name}</div>
          <div className={classesMeta[capitalize(application.status)]}>{formatStatus(application.status)}</div>
        </div>
        <ApplicationAction applications={[application]} />
      </div>
      <div
        id="messages"
        className="flex flex-col space-y-4 p-8 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch "
        style={{ minHeight }}
        ref={scrollRef}
      >
        {messages.map((message, index) => (
          <ChatMessage message={message} micro={application.micro} key={'chat-message' + index} />
        ))}
      </div>
      <div
        className="px-8 pt-1"
        style={{ borderTop: "1px solid rgba(151, 151, 151, 0.4)" }}
      >
        <TextArea
          as={TextareaAutosize}
          className="w-full resize-none"
          minRows={2}
          maxRows={4}
          autoFocus
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className="flex flex-row justify-between items-center">
          <div className="relative my-1 cursor-pointer">
            <button onClick={openInput} className=" text-white font-bold py-2 px-4 w-full inline-flex items-center">
              <ChatUploadIcon />
              <span className="ml-2 text-custom-gray text-sm">Upload File</span>
            </button>
          </div>
          <Button
            onClick={sendNewMessage}
            className="text-sm py-2 leading-none"
            primary="green"
          >
            Send
          </Button>

        </div>
      </div>
    </>
  )
}
