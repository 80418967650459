import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/InputField/InputField";
import ModalResetPassword from "../../../components/Modals/ModalResetPassword";
import { useLoginMutation } from "../../../api/global/hooks";

export const Button = styled.button`
  font-size: 17px;
  text-align: center;
  line-height: 1;
  border-radius: 21px;
  padding: 13px 12px;
  display: inline-block;
  min-width: 136px;
  max-width: 162px;
`;

export default function LoginPage() {


  const { mutate } = useLoginMutation();
  const [showModal, setShowModal] = useState(false);

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const submitLoginForm = (value) => mutate({ email: value.email, password: value.password })


  return (
    <>
      <div className="ml-auto mt-11">
        <h1 className="text-4xl mb-9 font-museo-sans-700">Sponsor Login</h1>
        <FormProvider {...form}>
          <form
            className="flex flex-col"
            onSubmit={form.handleSubmit(submitLoginForm)}
          >
            <div className="flex flex-row">
              <div className="flex flex-col" style={{ width: 409 }}>
                <InputField
                  placeholder="Email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  registerOptions={{
                    required: {
                      value: true,
                      message: "This is required",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  }}
                />
                <InputField
                  placeholder="Password"
                  autoComplete="current-password"
                  name="password"
                  type="password"
                  registerOptions={{
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  }}
                />
              </div>
            </div>
            <Button
              type="submit"
              className="bg-custom-yellow text-custom-blue font-bold"
            >
              Submit
            </Button>
            <div
              className="text-custom-white font-museo font-medium mt-3"
              style={{
                letterSpacing: "0.15px",
                fontSize: "0.8125rem",
                lineHeight: "1.4375rem",
              }}
            >
              Don’t have an account?
              <Link to="/apply" className="text-custom-green">
              &nbsp;Sign Up
              </Link>
              <br />
              <button onClick={() => setShowModal(true)} type="button" className="text-custom-green">
                Forgot password?
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      {showModal && (
        <ModalResetPassword showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
}
