import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc, query, orderBy, limit, Timestamp } from "firebase/firestore";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getChatMessages = (chatId) => {
  const colRef = collection(db, `/channels/${chatId}/thread`);
  const documentQuery = query(colRef, limit(100), orderBy("date"));
  return getDocs(documentQuery)
    .then(data => data.docs)
    .then(items => items.map(i => i.data()));

};

export const addChatMessageAsync = (chatId, { message = 'Empty message', senderId = '-1', contentType = 'text' } = {}) => {
  const colRef = collection(db, `/channels/${chatId}/thread`);

  const obj = {
    content: message,
    contentType: contentType,
    date: Timestamp.fromDate(new Date()),
    senderID: senderId,
  }

  addDoc(colRef, obj);
  return obj;
}
