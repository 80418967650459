import DashboardCard from "../DashboardCard/DashboardCard";
import DashboardLinearChart from "../DashboardLinearChart/DashboardLinearChart";
import DashboardPieChart from "../DashboardPieChart/DashboardPieChart";

const TYPE_TO_COMPONENT = {
  'card': DashboardCard,
  'barchart': DashboardLinearChart,
  'piechart': DashboardPieChart,
}

export function ChartFactory({ type, chart }) {
  const Component = TYPE_TO_COMPONENT[type];
  return <Component chart={chart} />
}
