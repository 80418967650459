import { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/icon/arrow-down.svg";
import { formatStatus } from "../../utils";
import Checkbox from "../CheckBox/CheckBox";

export default function SelectCheckBoxes({
  className,
  selectItems,
  filterInbox,
  placeholder,
  clearing
}) {
  const dropDownList = useRef();
  const [showDropDownList, setShowDropDownList] = useState(false);

  const toggleDropdown = () => {
    setShowDropDownList((state) => !state);
  };

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  useOutsideClick(dropDownList, () => {
    setShowDropDownList(false);
  });

  return (
    <>
      {selectItems && (
        <div
          className={`cursor-pointer font-semibold relative ${className}`}
          ref={dropDownList}
          style={{
            backgroundColor: "rgba(168, 172, 175, 0.15)",
            borderRadius: "10px",
            height: "2rem",
            zIndex: 10,
          }}
        >
          <div
            className="pl-2 h-full flex justify-between"
            onClick={toggleDropdown}
          >
            <div className="flex items-center leading-none text-sm select-none">
              {placeholder}
            </div>
            <div className="flex items-center leading-none fill-current text-custom-green p-1">
              <ArrowDownIcon />
            </div>
          </div>
          <div
            className="mt-3 overflow-hidden absolute top-7"
            style={{
              backgroundColor: "#eeeeee",
              borderRadius: "10px",
              display: showDropDownList ? "block" : "none",
              minWidth: "140px",
            }}
          >
            <ul className="w-full">
              {selectItems &&
                selectItems.map((item, index) => (
                  <li
                    className=" text-custom-gray text-sm py-3"
                    style={
                      selectItems.length !== index
                        ? {
                            borderBottom: "1px solid rgba(151, 151, 151, 0.2)",
                          }
                        : null
                    }
                    key={index}
                  >
                    {selectItems.length !== index}
                    <label
                      className="px-3 font-museo-sans font-medium cursor-pointer flex items-center leading-none"
                      title={item.label}
                    >
                      <Checkbox
                        checked={item.active}
                        onChange={(e) => filterInbox(item.id)}
                      />
                      <span className="ml-2 text-xs capitalize truncate hover:text-custom-blue">
                        {item.label}
                      </span>
                    </label>
                    {item.nested && item.nested.length > 0 && (
                      <ul className="w-full">
                        {item.nested.map((el, ind) => (
                          <li key={ind}>
                            <label
                              className="pt-2 px-3 pl-9 font-museo-sans font-medium cursor-pointer flex items-center leading-none"
                              title={el.label}
                            >
                              <Checkbox
                                checked={el.active}
                                onChange={(e) => filterInbox(el.id)}
                              />
                              <span className="ml-2 text-xs capitalize truncate hover:text-custom-blue">
                                {formatStatus(el.label)}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              {placeholder === "Status" && (
                <li className="px-1 font-museo-sans font-medium cursor-pointer leading-none text-custom-gray text-sm py-3">
                  <button className="ml-2 text-xs truncate hover:text-custom-blue" onClick={(e) => clearing()}>
                    Clear
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
