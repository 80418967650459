import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash';
import { useSponsorsListLazyQuery } from '../../api/global/hooks';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(168, 172, 175, 0.15)',
    borderRadius: '10px',
  }),
  input: (provided, state) => ({
    ...provided,
    backgroundColor: 'none',
    color: '#465c8b',
    outline: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#465c8b',
    backgroundColor: '#F2F3F3',
  }),
  singleValue: (provided, state) => {

    return {
      ...provided,
      color: '#465c8b',
    };
  }
}

export const CustomSponsorSelect = ({
  value = '',
  defaultValue,
  setValue,
} = {}) => {

  const [input, setInput] = useState('');

  const { data, refetch } = useSponsorsListLazyQuery(input);

  const fetchNewSponsors = useMemo(() => {
    return debounce(() => refetch, 350)
  }, [refetch]);

  useEffect(() => {
    fetchNewSponsors();
  }, [input, fetchNewSponsors]);


  const options = useMemo(() => {

    if (!data) return defaultValue ? [defaultValue] : [];
    const res = data.map(item => ({
      value: item.id,
      label: item.brand
    }));

    if (defaultValue) return [defaultValue, ...res]

    return [...res];
  }, [data, defaultValue])


  return (
    <Select
      maxMenuHeight={250}
      styles={customStyles}
      placeholder="Select sponsor..."
      options={options}
      value={value}
      onChange={setValue}
      onInputChange={setInput}
      inputValue={input}
    />
  )
}