import styled from "styled-components";


const LoaderDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background : rgba(1, 1, 1, 0.3);
  z-index: 1000;

`;

const CenteredDiv = styled.div`
    position:fixed;
    top: 50%;
    left: 50%;
    width:30em;
`

export const LoaderSpinner = styled.div`
display: inline-block;
width: 80px;
height: 80px;

&:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`


export default function Loader() {
  return (
    <LoaderDiv>
      <CenteredDiv>
        <LoaderSpinner />
      </CenteredDiv>
    </LoaderDiv>
  );
}
