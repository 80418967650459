export const MICROS_TABLE_HEADS = [
  {
    readName: 'Sponsor',
    sortName: 'brand',
  },
  {
    readName: 'Micros',
    sortName: 'name',
  },
  {
    readName: 'Status',
    sortName: 'status',
  },
  {
    readName: 'Kids',
    sortName: 'current_count',
  },
  {
    readName: 'Age',
    sortName: 'age_description',
  },
  {
    readName: 'Gender',
    sortName: 'sex',
  },
  {
    readName: 'Reward',
    sortName: 'reward',
  },

  {
    readName: 'Total',
    sortName: 'total',
  },
  {
    readName: 'Deadline',
    sortName: 'date_application_end',
  },
  {
    readName: 'Tags',
    sortName: 'tags',
  },
];

export const MICROS_TABS_NAMES = [
  'all',
  'published',
  'archived',
]

export const SPONSOR_TABS = [
  'Sponsors',
  'Sponsor applications',
]

export const scholarShipRange = [
  "< $100 - $1,000",
  "$1,001 - $5,000",
  "$5,001 - $25,000",
  "$25,001 - $100,000",
  "$100,001 - $1 million",
  "Above $1 million",
];

export const ageOptions = [...Array(17).keys()];

export const SPONSOR_APPLICATIONS_HEADS = [
  {
    readName: 'Sponsor',
    sortName: 'brand',
  },
  {
    readName: 'First name',
    sortName: 'first_name',
  },
  {
    readName: 'Last name',
    sortName: 'last_name',
  },
  {
    readName: 'Type',
    sortName: 'type',
  },
  {
    readName: 'Email',
    sortName: 'email',
  },
  {
    readName: 'Payout type',
    sortName: 'payout_type',
  },

];

export const SPONSOR_HEADS = [
  {
    readName: 'Sponsor',
    sortName: 'brand',
  },
  {
    readName: 'First name',
    sortName: 'first_name',
  },
  {
    readName: 'Last name',
    sortName: 'last_name',
  },
  {
    readName: 'Disabled',
    sortName: 'disabled',
  },

  {
    readName: 'Email',
    sortName: 'email',
  },
];

