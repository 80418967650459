import { confirmAlert } from 'react-confirm-alert'; // Import
import { GreenButton } from '../components/Buttons/GreenButton';
import { RedButton } from '../components/Buttons/RedButton';

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function nameFromLocation(location) {
  if (!location) return '-';
  return `${location.name}, ${location.state_code}`
}

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function confirmDialog({ title, message, OK_MESSAGE = 'OK' } = {}) {
  return new Promise((resolve) => {
    confirmAlert({
      title: title,
      message: message,
      customUI: ({ onClose }) => {
        return (
          <div className="bg-custom-blue rounded-lg p-10 text-white">
            {
              title && <h4 className="font-medium text-2xl mb-5">{title}</h4>
            }
            { message &&
              <p className="mb-5">{message}</p>
            }
            <GreenButton onClick={() => {
              resolve(true);
              onClose();
            }}>{OK_MESSAGE}</GreenButton>
            <RedButton
              onClick={() => {
                resolve(false);
                onClose();
              }}
            >
              Close
            </RedButton>
          </div>
        );
      }
    });
  });
}

export function formatNumber(number) {
  if(!number) return undefined
  return new Intl.NumberFormat().format(number)
}

export function formatDate(ddmmyyyy) {
  if(!ddmmyyyy) return ''
  const separate_date = ddmmyyyy.split('-')
  const day = separate_date.shift()
  separate_date.splice(1,0, day)
  return separate_date.join('-')
}

export function formatStatus(status){
  if (status.includes('_')){
    const words = status.split('_').map(word => capitalize(word))
    return words.join(' ')
  }
  return capitalize(status)
}
