import { ReactComponent as DateIcon } from "../../assets/icon/date-icon.svg";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

const WEEKDAYS_LONG = {
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
};

const WEEKDAYS_SHORT = {
  en: ["S", "M", "T", "W", "T", "F", "S"],
};

const MONTHS = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
};

const FIRST_DAY = {
  en: 0,
};

const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
};

function formatDay(d, locale = "en") {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${MONTHS[locale][d.getMonth()]
    } ${d.getFullYear()}`;
}

function formatMonthTitle(d, locale = "en") {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i, locale) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i, locale) {
  return WEEKDAYS_SHORT[locale][i];
}

function getFirstDayOfWeek(locale) {
  return FIRST_DAY[locale];
}

export default function DatePickerField({ prefixText, onDayChange, defaultValue = null }) {
  return (
    <div className="relative w-full">
      <span className="text-custom-gray font-bold absolute top-3 left-4">
        {prefixText}:
      </span>
      <span className="absolute top-3" style={{ right: "112px" }}>
        <DateIcon />
      </span>
      <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={defaultValue ? `${formatDate(defaultValue)}` : `${formatDate(new Date())}`}
        onDayChange={onDayChange}
        dayPickerProps={{
          locale: "en",
          localeUtils: localeUtils,
        }}
      />
    </div>
  );
}
