import { NavLink } from "react-router-dom";
import { ReactComponent as LogoColor } from "../../../assets/icon/logo-color.svg";
import { ReactComponent as UserIcon } from "../../../assets/icon/user-icon.svg";
// Menu Icons
import { ReactComponent as DashboardIcon } from "../../../assets/icon/menu-dashboard-icon.svg";
import { ReactComponent as MicroIcon } from "../../../assets/icon/menu-micro-icon.svg";
import { ReactComponent as KidsIcon } from "../../../assets/icon/menu-kids-icon.svg";
import { ReactComponent as InboxIcon } from "../../../assets/icon/menu-inbox-icon.svg";
import { ReactComponent as LogOutIcon } from "../../../assets/icon/menu-log-out-icon.svg";
import { ReactComponent as PopularIcon } from "../../../assets/icon/menu-popular-icon.svg";
import { ReactComponent as FaqIcon } from "../../../assets/icon/menu-faq-icon.svg";
import { ReactComponent as AboutIcon } from "../../../assets/icon/menu-about-icon.svg";
import { ReactComponent as BlenderIcon } from "../../../assets/icon/blender_fc.svg";
import { useProfile } from "../../../api/global/hooks";
import Loader from "../../Loader/Loader";
import { USER_TYPE } from '../../../api/global/constants';


const borderBottom = {
  borderBottom: "1px solid rgba(151, 151, 151, 0.4)",
};

export default function DashboardSidebar() {

  const { data: profile } = useProfile();

  const handlerLogOut = () => {
    localStorage.clear();
    window.location.replace('/');
  };

  if (!profile) return <Loader />

  return (
    <div className=" absolute sm:relative w-72 bg-custom-blue min-h-screen flex-col justify-between hidden sm:flex overflow-y-visible	"   >
      <div>
        <div className="w-full pt-7 pl-8 pr-6 pb-4" style={borderBottom}>
          <LogoColor width={171} />
          <span className="text-lg" style={{ lineHeight: "2.4rem" }}>
            Micros
          </span>
        </div>
        <NavLink to="/dashboard/profile">
          <div
            className="w-full py-4 pl-8 pr-6 flex flex-row items-center"
            style={borderBottom}
          >
            {
              profile?.type ===  USER_TYPE.SUPER_ADMIN?
                <div
                  className="profileImage rounded-xl overflow-hidden flex items-center justify-center"
                  style={{background: `url(https://storage.googleapis.com/kidcoin/premium/premium.png) no-repeat 50% 50%`}}
                />
              :
                <div
                  className="profileImage rounded-xl overflow-hidden flex items-center justify-center"
                  style={
                    profile?.logo !== ""
                      ? {
                        background: `url(${profile?.logo}) no-repeat 50% 50%`
                      }
                      : { backgroundColor: "#eeeeee" }
                  }
                >
                  {(!profile.hasOwnProperty('logo') || profile?.logo === "" || profile?.logo === null) && <UserIcon width={57} />}
                </div>
            }
            <span
              className="ml-6 text-xl text-custom-yellow"
              style={{ lineHeight: "2.4rem" }}
            >
              {profile.type === USER_TYPE.SUPER_ADMIN && "Admin"}
              {
                profile.type === USER_TYPE.ADMIN?
                  profile?.brand? profile.brand:`${profile?.first_name} ${profile?.last_name}`
                :
                  null
              }
            </span>
          </div>
        </NavLink>
        <nav>
          <ul className="py-4" style={borderBottom}>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center ">
              <NavLink
                to="/dashboard"
                exact
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
                activeClassName="active"
              >
                <DashboardIcon />
                <span className="text-lg ml-5">Dashboard</span>
              </NavLink>
            </li>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                to="/dashboard/micros"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
                activeClassName="active"
              >
                <MicroIcon />
                <span className="text-lg ml-5">Micros</span>
              </NavLink>
            </li>
            {
              profile.type === USER_TYPE.SUPER_ADMIN &&
              <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
                <NavLink
                  to="/dashboard/sponsors"
                  className="flex items-center focus:outline-none py-3 pl-8 w-full"
                  activeClassName="active"
                >
                  <KidsIcon />
                  <span className="text-lg ml-5">Sponsors</span>
                </NavLink>
              </li>
            }
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                to="/dashboard/kids"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
                activeClassName="active"
              >
                <KidsIcon />
                <span className="text-lg ml-5">Kids</span>
              </NavLink>
            </li>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                to="/dashboard/inbox"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
                activeClassName="active"
              >
                <InboxIcon />
                <span className="text-lg ml-5">Inbox</span>
              </NavLink>
            </li>
            {
              profile.type === USER_TYPE.SUPER_ADMIN &&
              <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
                <NavLink
                  to="/dashboard/integrations/blendfc"
                  className="flex items-center focus:outline-none py-3 pl-8 w-full"
                  activeClassName="active"
                >
                  <BlenderIcon className="h-6 w-6" />
                  <span className="text-lg ml-5">Blend FC</span>
                </NavLink>
              </li>
            }
          </ul>
          <ul className="py-4">
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center ">
              <button
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
                onClick={handlerLogOut}
              >
                <LogOutIcon />
                <span className="text-lg ml-5">Log Out</span>
              </button>
            </li>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                to="/popular"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
              >
                <PopularIcon />
                <span className="text-lg ml-5">Popular</span>
              </NavLink>
            </li>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                to="/faq"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
              >
                <FaqIcon />
                <span className="text-lg ml-5">FAQs</span>
              </NavLink>
            </li>
            <li className="flex w-full justify-between text-custom-white cursor-pointer items-center">
              <NavLink
                exact
                to="/"
                className="flex items-center focus:outline-none py-3 pl-8 w-full"
              >
                <AboutIcon />
                <span className="text-lg ml-5">About</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
