import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useIntervalWhen } from "rooks";
import { addChatMessageAsync, getChatMessages } from '../utils/firestore';
import { useUploadImage } from '../api/global/hooks';


export const useChat = (chatId) => {
  const [messages, setMessages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { mutateAsync, isLoading } = useUploadImage();

  const sendMessage = async (message, senderId) => {
    const obj = addChatMessageAsync(chatId, { message, senderId });
    setMessages([...messages, obj]);
    setLoaded(!loaded);
  };

  const sendImage = async (image, senderId) => {
    const formData = new FormData();
    formData.append('file', image);

    const res = await mutateAsync(formData);
    const obj = addChatMessageAsync(chatId, { message: res.file, senderId, contentType: 'image' });

    setMessages([...messages, obj]);
    setLoaded(!loaded);

  }


  useEffect(() => {
    if (!chatId) return;

    getChatMessages(chatId)
      .then(messages => {
        setMessages(messages);
      })
      .finally(() => setLoaded((state) => !state));

  }, [chatId])

  useIntervalWhen(
    async () => {

      try {
        if (!chatId) return;
        const newMessages = await getChatMessages(chatId);

        if (
          newMessages.length === 0 ||
          messages.length !== newMessages.length ||
          !isEqual(messages[messages.length - 1], newMessages[newMessages.length - 1])
        ) {
          setMessages(newMessages);
        }

      } catch (e) {
        alert("ERROR, refresh page");
      }
    },
    10 * 1000,
  );

  return {
    messages,
    sendMessage,
    loaded,
    sendImage,
    isLoading,
  }
}