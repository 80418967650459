import { useMemo } from "react";
import { useHistory, useParams } from "react-router"
import { toast } from "react-toastify";
import { ReactComponent as LeftArrowBlueicon } from "../../../assets/icon/left-arrow-blue-icon.svg";
import { useGetSponsorshipApplications, useSponsorDecision } from "../../../api/global/hooks";
import { ApplySponsor } from "../../../components/ApplySponsor/ApplySponsor"
import { GreenButton } from "../../../components/Buttons/GreenButton";
import { RedButton } from "../../../components/Buttons/RedButton";
import Loader from "../../../components/Loader/Loader";
import { confirmDialog } from "../../../utils";

export const SponsorApplication = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, isLoading } = useGetSponsorshipApplications();

  const { mutateAsync, isLoading: isLoadingDecision } = useSponsorDecision(id);

  const approve = async () => {
    const shouldAction = await confirmDialog({
      title: 'Sponsor application',
      message: 'Are you sure to approve ?',
      OK_MESSAGE: 'Approve'
    });
    if (!shouldAction) return;
    await mutateAsync({ decision: 'approved' });
    toast('Application has been approved');
    history.push('/dashboard/sponsorApps');
  }

  const decline = async () => {
    const shouldAction = await confirmDialog({
      title: 'Sponsor application',
      message: 'Are you sure to decline ?',
      OK_MESSAGE: 'Decline'
    });
    if (!shouldAction) return;

    await mutateAsync({ decision: 'denied' });
    toast('Application has been declined');
    history.push('/dashboard/sponsorApps');
  }

  const sponsorApplication = useMemo(() => {
    if (!data || !id) return {};
    return data.find(x => x.id === parseInt(id));
  }, [data, id]);

  if (isLoading || isLoadingDecision) return <Loader />

  return (
    <div>
      <div className="px-8 py-5 bg-custom-yellow flex">
        <LeftArrowBlueicon onClick={history.goBack} className="mr-4 my-auto  cursor-pointer" />
        <h1 className="text-4xl font-bold">Sponsor application</h1>
      </div>
      <div className="mx-5 mt-5">
        <ApplySponsor defaultValues={sponsorApplication} readOnly={true} />
      </div>
      <div className="flex w-full mb-5">
        <GreenButton className="w-full mx-3" onClick={approve}>Approve</GreenButton>
        <RedButton className="w-full mx-3" onClick={decline}>Reject</RedButton>
      </div>
    </div>
  )
}