import { ApplySponsor } from "../../../components/ApplySponsor/ApplySponsor";


export default function ApplyPage() {
  return (
    <div className="ml-auto mt-11">
      <h1 className="text-4xl mb-9 font-museo-sans-700">
        Apply to be a Sponsor
      </h1>
      <ApplySponsor />
    </div>
  )
}
