import MicroInbox from './MicroInbox';
import Loader from '../../../components/Loader/Loader';
import { useAllApplicationsQuery } from '../../../api/micros/hooks.queries';

export default function InboxPage() {

  const { data: applications, isLoading } = useAllApplicationsQuery();

  if (isLoading) return <Loader />

  return <>
    <div className="pl-8 pr-8 pt-10 pb-10 bg-custom-yellow flex justify-between">
      <h1 className="text-4xl font-bold">Inbox</h1>
    </div>
    <MicroInbox applications={applications} />
  </>
}
