import { useHistory } from "react-router";

export default function TableKidsItem({ kid }) {
  const history = useHistory();

  const redirectToChat = () => history.push(`/dashboard/kids/chat/${kid.id}`);

  return (
    <tr
      key={kid.id}
      onClick={redirectToChat}
      className="cursor-pointer"
    >
      <td
        valign="middle"
        className="px-6 pl-8 py-3 whitespace-no-wrap border-b border-grey text-custom-blue w-48"
      >
        {kid.name}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-16"
      >
        {kid.age}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue w-52"
      >
        {kid.location}
      </td>
      <td
        valign="middle"
        className="px-6 py-3 whitespace-no-wrap border-b border-grey text-sm text-custom-blue"
      >
        {kid.micros}
      </td>
    </tr>
  );
}
