import { useMemo } from "react";

import ApexCharts from "react-apexcharts";
import { formatDate, formatNumber } from "../../../utils";

export default function DashboardLinearChart({ chart }) {

  const linearChart = useMemo(() => {
    if (!chart) return null;
    const { title, data, categories } = chart;
    return {
      series: [
        {
          name: title,
          data: data,
        },
      ],
      options: {
        legend: {
          show: false,
        },
        theme: {
          mode: "dark",
        },
        colors: ["#f7ef6a", "#93c178"],
        chart: {
          parentHeightOffset: 0,
          background: "transperant",
          redrawOnParentResize: true,
          redrawOnWindowResize: true,
          offsetX: -10,
          offsetY: 0,
          toolbar: {
            show: false,
          },
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
          width: [3, 3],
        },
        title: {
          text: "New Applications",
          offsetX: 25,
          offsetY: 10,
        },
        grid: {
          show: false,
        },
        yaxis: {
          showAlways: true,
          axisBorder: {
            show: true,
            color: "#fff",
            offsetX: 0,
            offsetY: 0,
          },
          labels:{
            formatter: formatNumber
          }
        },
        xaxis: {
          labels: {
            rotateAlways: false,
            show: true,
            style: {
              fontSize: "11px",
            },
            formatter: formatDate
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            color: "#eee",
          },
          categories: categories,
        },
      },
    }
  }, [chart])

  return (
    <div id="linear-chart">
      <ApexCharts
        className="bg-custom-blue text-custom-white rounded-xl"
        options={linearChart.options}
        series={linearChart.series}
        type="line"
        height={"200"}
      />
    </div>
  );
}
